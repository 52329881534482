import React, { useEffect, useContext, useState } from "react";
import "./ByIndividualTire.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Grid,
  Button
} from "@material-ui/core";
import ScrapTiresNavigation from "../../../scrap-tires-navigation/components/ScrapTiresNavigation";
import TiresService from "../../../../../services/TiresApis.js";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import ByIndividualTireTable from "../table/ByIndividualTireTable";
import ByIndividualTireDataSetter from "../../../../../services/ByIndividualTireUtils";
import CommonUtils from "../../../../../../../services/utils";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByIndividualTireFilter from '../filter/ByIndividualTireFilter.js'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  proceed: {
      "&:hover": {
          opacity: 1
      },
      background:
          "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: 'Barlow',
      opacity: .8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none"
  }
});

const ByIndividualTire = () => {
  // GLOBAL ACCOUNT STATE management
  const commonUtils = new CommonUtils();
  const byIndividualTireDataSetter = new ByIndividualTireDataSetter();
  const tiresService = new TiresService();
  const globalizationMapping = new Globalization();
  const styles = useStyles();

  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [byIndividualTireTableData, setByIndividualTireTableData] = useState();
  const [originalByIndividualTireTableData, setOriginalByIndividualTireTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byIndividualTireTableData);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: "",
  });
  const [removeAt, setRemoveAt] = useState(localStorage.getItem("defaultRemovalTreadDepth"));
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [tableDataSettings, setTableDataSettings] = useState({
    tireScrapType: "AllTire",
    removeAt: localStorage.getItem("defaultRemovalTreadDepth"),
    excludeUsedTires: false,
  });
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" },
      { name: "Removal Reason", value: "RemovalReason" },
      { name: "Eval #", value: "Eval" },
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      RemovalReason: [],
      Eval: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      // RemoveAt: "",
    },
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;


  useEffect(() => {
    ByIndividualTireResponseData(
      tireScrapType,
      removeAt,
      excludeUsedTires,
      filterApplied,
      filterFieldsApplied
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const ByIndividualTireResponseData = (
    tireScrapTypeValue,
    removeAtValue,
    excludeUsedTiresValue,
    filterAppliedValue,
    filterFieldsAppliedValue
  ) => {

    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let inServiceDateRangeType = "AllTime";
    let outServiceDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "")
      inServiceDateRangeType = "DateRange";

    if (
      outServiceDateRange.fromDate !== "" &&
      outServiceDateRange.toDate !== ""
    )
      outServiceDateRangeType = "DateRange";

    let requestObject = {
      reportOption: "",
      reportName: "Scrap_Report_Individual_Tire",
      dateRangeType: dateRangeType,
      InServiceDateRangeType: inServiceDateRangeType,
      OutServiceDateRangeType: outServiceDateRangeType,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      TireScrapType: tireScrapTypeValue,
      usedTires: excludeUsedTiresValue.toString(),
      RemoveAt: removeAtValue === null ? null : parseInt(removeAtValue, 10),
    };
    let filterOptions = {
      categories: [
        {name: globalizationMapping.REPORTTABLE.MANUFACTURER,value: "ManufacturerName"},
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.EVALNO, value: "Eval" },
        {name: globalizationMapping.REPORTTABLE.COMPOUND1, value: "CompoundName"},
        {name: globalizationMapping.REPORTTABLE.REMOVALREASON, value: "RemovalReason"},
      ],
      categoryData: {
        ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      RemovalReason: [],
      Eval: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      // RemoveAt: "",
      },
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let byIndividualTireTableInfo = JSON.parse(JSON.stringify(byIndividualTireDataSetter.setData(data)));
      let filterOptionsInfo = byIndividualTireDataSetter.setFilterOptionsData(
        filterOptions,
        byIndividualTireTableInfo.rowsData
      );
      let updatedHeadCells = byIndividualTireDataSetter.updatedHeadCells();
      setByIndividualTireTableData(byIndividualTireTableInfo);
      byIndividualTireTableInfo.rowsData.length === 0
        ? setNoData(true)
        : setNoData(false);

      let arrOfCol = [
        "CostPerMile",
        "AdjustmentCostPerMile",
        "MilesPerTreadDepth",
        "TotalMiles",
      ];
      const filteredHeadCells = byIndividualTireTableInfo.headCells.filter(
        (item) => {
          let flag = true;
          arrOfCol.forEach((term) => {
            if (item.value === term) flag = false;
          });
          return flag;
        }
      );

      setDisplayColOptions(byIndividualTireTableInfo.headCells);
      setDisplayColumns(filteredHeadCells);

      let currentHeadCells = byIndividualTireTableInfo.headCells;
      let showColumnsUpdated = byIndividualTireDataSetter.setDisplayColumnData(
        currentHeadCells,
        filteredHeadCells
      );
      setShowColumns(showColumnsUpdated);
      // when all columns selected
      if (
        displayColOptions &&
        filteredHeadCells.length === displayColOptions?.length
      ) {
        setPrintData({
          reportType: "Scrap Tires : ",
          reportName:
            globalizationMapping.TIREREPORTS.SCRAPTIRES.ByIndividualTire,
          rowsData: byIndividualTireTableInfo.rowsData,
          headCells: updatedHeadCells,
          avgValues: byIndividualTireDataSetter.avgValues(
            byIndividualTireTableInfo.rowsData
          ),
          selectedColShow: showColumnsUpdated,
          filterApplied: false,
        });
      } else {
        setPrintData({
          reportType: "Scrap Tires : ",
          reportName:
            globalizationMapping.TIREREPORTS.SCRAPTIRES.ByIndividualTire,
          rowsData: byIndividualTireTableInfo.rowsData,
          headCells: updatedHeadCells,
          avgValues: byIndividualTireDataSetter.avgValues(
            byIndividualTireTableInfo.rowsData
          ),
          selectedColShow: showColumnsUpdated,
          filterApplied: true,
        });
      }
      filteredHeadCells.length === 0 ? setNoData(true) : setNoData(false);

      setOriginalByIndividualTireTableData(byIndividualTireTableInfo);
      setTableBeforeFilter(byIndividualTireTableInfo);
      setFilterOptions(filterOptionsInfo);

      setShowSpinner(false);

      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byIndividualTireTableInfo.rowsData;
        let filteredData = byIndividualTireDataSetter.filterTable(
          filterFieldsAppliedValue,
          originalTableDataStore
        );
        setByIndividualTireTableData({
          ...byIndividualTireTableInfo,
          rowsData: filteredData,
          avgValues: byIndividualTireDataSetter.avgValues(filteredData),
        });
        setOriginalByIndividualTireTableData({
          ...byIndividualTireTableInfo,
          rowsData: filteredData,
          avgValues: byIndividualTireDataSetter.avgValues(filteredData),
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byIndividualTireDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true,
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        (((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      RemovalReason: [],
      Eval: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      // RemoveAt: '',
    });
    setFilterApplied(false);
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setByIndividualTireTableData(tableBeforeFilter);
    setOriginalByIndividualTireTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {},
    });
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let originalTableDataStore, filteredData;
    originalTableDataStore = originalByIndividualTireTableData?.rowsData;

    filteredData = byIndividualTireDataSetter.search(
      originalTableDataStore,
      searchText
    );
    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalByIndividualTireTableData?.headCells,
      avgValues: byIndividualTireDataSetter.avgValues(filteredData),
      selectedColShow: originalByIndividualTireTableData?.selectedColShow,
    };
    setByIndividualTireTableData(updatedTableData);
    setPrintData({
      ...printData,
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByIndividualTire,
      rowsData: filteredData,
      headCells: originalByIndividualTireTableData?.headCells,
      avgValues: byIndividualTireDataSetter.avgValues(filteredData),
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalByIndividualTireTableData?.headCells;
    let showColumnsUpdated = byIndividualTireDataSetter.setDisplayColumnData(
      currentHeadCells,
      selected
    );
    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    let toSetCurrentFilter = 
    { ...appliedFilter,
      outServiceDateToDate: outServiceDateRange.toDate,
      outServiceDateFromDate:  outServiceDateRange.fromDate,
      inServiceDateFromDate: inServiceDateRange.fromDate,
      inServiceDateToDate: inServiceDateRange.toDate,
   };
    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    ByIndividualTireResponseData(tireScrapType, removeAt, excludeUsedTires, true, appliedFilter);
    let originalTableDataStore = originalByIndividualTireTableData?.rowsData;
    let filteredData = byIndividualTireDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setByIndividualTireTableData({
      ...byIndividualTireTableData,
      rowsData: filteredData,
      avgValues: byIndividualTireDataSetter.avgValues(filteredData),
    });
    setPrintData({
      ...printData,
      avgValues: byIndividualTireDataSetter.avgValues(filteredData),
      rowsData: filteredData,
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
  };


  const handleUpdateTireScrapType = (event) => {
    setTireScrapType(event.target.value);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
  };

  const handleRemoveAt = (value) => {
        setRemoveAt(value);
      }

const applyScrapTireSettings = () => {
  setTableDataSettings ({
    ...tableDataSettings,
    tireScrapType,
    removeAt,
    excludeUsedTires,
  })
  ByIndividualTireResponseData(
    tireScrapType,
    removeAt,
    excludeUsedTires,
    filterApplied,
    filterFieldsApplied
  );
}


  const actionChanged = (action) => {
    if (action === "Miles") {
      handleMilesClick();
    } else {
      handleHoursClick();
    }
  };
  const handleMilesClick = () => {

    let arrOfCol = [
      "CostPerHour",
      "AdjustmentCostPerHour",
      "HoursPerTreadDepth",
      "CurrentHours",
    ];
    const displayColSection = byIndividualTireTableData?.headCells.filter(
      (item) => {
        let flag = true;
        arrOfCol.forEach((term) => {
          if (item.value === term || item.value === "BrandNo") flag = false;
        });
        return flag;
      }
    );
    setDisplayColOptions(byIndividualTireTableData?.headCells);
    setDisplayColumns(displayColSection);

    let currentHeadCells = originalByIndividualTireTableData?.headCells;
    let showColumnsUpdated = byIndividualTireDataSetter.setDisplayColumnData(
      currentHeadCells,
      displayColSection
    );
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false,
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true,
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  const handleHoursClick = () => {

    let arrOfCol = [
      "CostPerMile",
      "AdjustmentCostPerMile",
      "MilesPerTreadDepth",
      "TotalMiles",
    ];
    const displayColSection = byIndividualTireTableData?.headCells.filter(
      (item) => {
        let flag = true;
        arrOfCol.forEach((term) => {
          if (item.value === term || item.value === "BrandNo") flag = false;
        });
        return flag;
      }
    );
    setDisplayColOptions(byIndividualTireTableData?.headCells);
    setDisplayColumns(displayColSection);

    let currentHeadCells = originalByIndividualTireTableData?.headCells;
    let showColumnsUpdated = byIndividualTireDataSetter.setDisplayColumnData(
      currentHeadCells,
      displayColSection
    );
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false,
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true,
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  return (
    <div className="container">
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-individual-tire-container">
          <Card className="by-individual-tire-card">
            <CardContent className="table-card">
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form
                      className="search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={
                          globalizationMapping.REPORTCOMMONWORDS.Search
                        }
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div
                    className="display-columns-container"
                    id="dashboard-display-column"
                  >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}
                      </span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems:
                          globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected:
                          globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll:
                          globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                      }}
                    />
                  </div>
                  <div
                    className="display-columns-container"
                    id="dashboard-display-column"
                  >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {
                          globalizationMapping.REPORTCOMMONWORDS
                            .ChangeHoursAndMiles
                        }
                      </span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      onChange={(e) => actionChanged(e.target.value)}
                    >
                      <option value={"Hours"}>
                        {globalizationMapping.MANAGEVEHICLE.HOURS}
                      </option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                </div>

                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container item xs={4} spacing={1} style={{borderStyle:'dashed', borderColor:'#07469e87',borderWidth:'thin',padding:5 }}>
                <Grid item >
                  <div className="tire-scrap-type">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.GRAPH.TireScrapType}
                      </span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down"
                      value={tireScrapType}
                      name="tire scrap type"
                      variant="outlined"
                      onChange={handleUpdateTireScrapType}
                    >
                      <option value="AllTire">
                        {globalizationMapping.REPORTCOMMONWORDS.TypeOption1}{" "}
                      </option>
                      <option value="Repair">
                        {globalizationMapping.REPORTCOMMONWORDS.TypeOption2}{" "}
                      </option>
                      <option value="Retread">
                        {globalizationMapping.REPORTCOMMONWORDS.TypeOption3}{" "}
                      </option>
                      <option value="NewTire">
                        {globalizationMapping.REPORTCOMMONWORDS.TypeOption4}{" "}
                      </option>
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={2}>
                <div className="tire-scrap-type">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        Remove At
                      </span>
                    </InputLabel>
                    <TextField
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={removeAt}
                        onChange={(e) => handleRemoveAt(commonUtils.minmaxInt(e.target.value, 0, 999999))} 
                        inputProps= {{maxLength: 3 }} 
                        min
                    />
                  </div>
                </Grid>
                <Grid item >
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={excludeUsedTires}
                          onChange={handleExcludeUsedTires}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
                <Grid item style={{marginTop:'2.5%', marginLeft:'4%'}}>
                <Button
                    className={styles.proceed}
                    variant="contained"
                    disableElevation={true}
                    onClick={() => applyScrapTireSettings()}
                >
                    {globalizationMapping.INSPECT.UPDATE}
                </Button>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <ByIndividualTireTable
                  tableData={byIndividualTireTableData}
                  showColumns={showColumns}
                  filterApplied={filterApplied}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
        tableDataSettings={tableDataSettings}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
        tableDataSettings={tableDataSettings}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />

      <ByIndividualTireFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByIndividualTire;
