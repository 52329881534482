import "./TireForecast.css";
import TireForecastTable from "../table/TireForecastTable";
import TireForecastDataSetter from "../../../../services/TireForecastUtils";
import React, { useEffect, useContext, useState } from "react";
import ByTiresNavigation from "../../by-tires-navigation/ByTiresNavigation";
import Spinner from "../../../../../../common-components/spinner/spinner";
import "../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Grid, Select, FormControlLabel, Checkbox, makeStyles, } from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
import { StateContext } from "../../../../../../services/StateProvider";
import SearchIcon from "../../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import { accountInfoIdsFormatter } from "../../../../../../services/AccountInfoHandler";
import TiresService from "../../../../../tires-reports/services/TiresApis";
import CommonUtils from "../../../../../../services/utils";
import Globalization from "../../../../../../globalization";
import { useComponentVisible } from "../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TireForecastFilter from "../filter/TireForecastFilter";




const TireForecast = () => {
  // GLOBAL ACCOUNT STATE management
  const commonUtils = new CommonUtils();
  const tireForecastDataSetter = new TireForecastDataSetter();
  const tiresService = new TiresService();
  const globalizationMapping = new Globalization();
  const filterSectionWords = globalizationMapping.FILTERSECTION;
  
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [tireForecastTableData, setTireForecastTableData] = useState();
  const [originalTireForecastTableData, setOriginalTireForecastTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tireForecastTableData);
  const [printDataNotPDF, setPrintDataNotPDF] = useState(tireForecastTableData);

  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [averageHoursOfUsePerDay, setAverageHoursOfUsePerDay] = useState(13);
  const [averageWorkDaysPerWeek, setAverageWorkDaysPerWeek] = useState(7);
  const [steeringWheelsForecastType, setSteeringWheelsForecastType] = useState("treadWearRate");
  const [drivingWheelsForecastType, setDrivingWheelsForecastType] = useState("treadWearRate");
  const [removeAt, setRemoveAt] = useState(localStorage.getItem("defaultRemovalTreadDepth"));
  const [steeringWheelsFixedRemovalHours, setSteeringWheelsFixedRemovalHours] = useState(14000);
  const [drivingWheelsFixedRemovalHours, setDrivingWheelsFixedRemovalHours] = useState(14000);
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [tableDataSettings, setTableDataSettings] = useState({
    averageHoursOfUsePerDay: 13,
    averageWorkDaysPerWeek: 7,
    steeringWheelsForecastType: 'treadWearRate',
    drivingWheelsForecastType: 'treadWearRate',
    removeAt:localStorage.getItem("defaultRemovalTreadDepth"),
    steeringWheelsFixedRemovalHours: 14000,
    drivingWheelsFixedRemovalHours: 14000,
    excludeUsedTires: false,
  });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [keys, setKeys] = useState([]);

  const [toggleHourMiles, setToggleHourMiles] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Vehicle Id", value: "VehicleId" },
      { name: "Model", value: "ModelName" },
      { name: "Type", value: "TypeName" }
    ],
    categoryData: {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  const useStyles = makeStyles({
    filter: {
      "&:hover": {
        opacity: 1,
      },
      background:
        "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    filterDisabled: {
      background:commonUtils.disabledColor,
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 600,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    root: {
      "& input.MuiAutocomplete-inputFocused": {
        color: "#000000 !important",
        Height: "45px",
      },
    },
    clear: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "none",
        opacity: 1,
        boxShadow: "none",
      },
      background: "transparent",
      border: "none",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: 5,
    },
    FormHelperText: {
      marginTop: 25,
    },
  });
  const classes = useStyles();



  const formatProjectRemovalDate = (rowsData) => {
    const updateRowsData1 = [...rowsData];
    const updatedRowsData = updateRowsData1.map(innerArray =>
      innerArray.map(data => ({
        ...data,
        ProjectedRemovalDate: data.ProjectedHours === 0 ? 'N/A' : data.ProjectedRemovalDate,
        ProjectedRemovalDateByMiles: data.ProjectedMiles === 0 ? 'N/A' : data.ProjectedRemovalDateByMiles,
      }))
    );
    return updatedRowsData
  }

  const TireForecastResponseData = (filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Tire_Forcast", // "Tire_Forcast" is the needed name for BE (ask TCS for it)
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      AverageHoursOfUsePerDay: averageHoursOfUsePerDay,
      AverageWorkDaysPerWeek: averageWorkDaysPerWeek,
      steeringWheelsForecast: steeringWheelsForecastType,
      drivingWheelsForecast: drivingWheelsForecastType,
      steeringFixedRemovalHours: steeringWheelsFixedRemovalHours,
      drivingFixedRemovalHours: drivingWheelsFixedRemovalHours,
      removeAt: removeAt == null ? null : parseInt(removeAt, 10),
      usedTires: excludeUsedTires.toString()
    };
    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      console.log(data,'data')
      let tireForecastTableInfo = tireForecastDataSetter.setData(data);
      let tempKeys = data.model.columnDefinitions.map((item) => (item.fieldName ? item.text : null));
      tempKeys = tempKeys.filter((item) => {
        if (item) return item;
      });
      setKeys(tempKeys);

      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEID,
            value: "VehicleId"
          },
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEMODEL,
            value: "ModelName"
          },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" }
        ],
        categoryData: {
          VehicleId: [],
          ModelName: [],
          TypeName: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = tireForecastDataSetter.setFilterOptionsData(filterOptions, tireForecastTableInfo.rowsDataFilterOptions);
      setTireForecastTableData(tireForecastTableInfo);
      tireForecastTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setOriginalTireForecastTableData(tireForecastTableInfo);
      setTableBeforeFilter(tireForecastTableInfo);
      setFilterOptions(filterOptionsInfo);
      let arrOfCol = ["TotalMiles", "ProjectedMiles", "MilesLeft", "ProjectedCostByMiles", "ProjectedRemovalDateByMiles", "MilesPerTreadDepth"];
      const updatedHeadCells = tireForecastTableInfo.headCells.filter((item) => {
        let flag = true;
        arrOfCol.forEach((term) => {
          if (item.value === term) flag = false;
        });
        return flag;
      });
      setDisplayColOptions(updatedHeadCells);
      if (displayColumns === "") setDisplayColumns(updatedHeadCells);
      else {
        changeDisplayColumns(displayColumns);
      }

      setShowColumns(tireForecastTableInfo.selectedColShow);

      setPrintData({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
        rowsData: formatProjectRemovalDate(tireForecastTableInfo.rowsData),
        headCells: tireForecastTableInfo.headCells,
        vehicleData: tireForecastTableInfo.vehicleData,
        selectedColShow: tireForecastTableInfo.selectedColShow
      });
      let exportData = JSON.parse(JSON.stringify(tireForecastTableInfo));
      for (let index = 0; index < exportData.rowsData.length; index++) {
        let vehicleId = exportData.vehicleData[index].VehicleId;
        exportData.rowsData[index].forEach((tireRow) => {
          tireRow.VehicleId = vehicleId;
        });
      }
      exportData.headCells.unshift({
        key: "VehicleId",
        keyIndex: exportData.headCells.length,
        label: "Vehicle Id",
        numeric: true,
        value: "VehicleId"
      });
      exportData.selectedColShow.VehicleId = true;

      setPrintDataNotPDF({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
        rowsData: exportData.rowsData,
        vehicleData: exportData.vehicleData,
        headCells: exportData.headCells,
        selectedColShow: exportData.selectedColShow
      });

      if (filterAppliedValue) {
        setSearchValue("");
        setFilterApplied(true);

        let originalVehicleDataStore = tireForecastTableInfo.vehicleData;
        let originalRowsDataStore = tireForecastTableInfo.rowsData;
        let originalTireVehicleMappedDataStore = tireForecastTableInfo.tireVehicleMappedData;
        let filteredVehicleData = tireForecastDataSetter.filterTable(filterFieldsAppliedValue, originalVehicleDataStore);
        let filteredRowsData = tireForecastDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);
        let filteredVehicleMappedData = tireForecastDataSetter.setAxleDataOnUpdate(filteredVehicleData, originalTireVehicleMappedDataStore);

        setTireForecastTableData({
          ...tireForecastTableInfo,
          rowsData: formatProjectRemovalDate(filteredRowsData),
          vehicleData: filteredVehicleData,
          tireVehicleMappedData: filteredVehicleMappedData
        });
        setOriginalTireForecastTableData({
          ...tireForecastTableInfo,
          rowsData: formatProjectRemovalDate(filteredRowsData),
          vehicleData: filteredVehicleData,
          tireVehicleMappedData: filteredVehicleMappedData
        });
        setPrintData({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
          rowsData: formatProjectRemovalDate(filteredRowsData),
          vehicleData: filteredVehicleData,
          headCells: tireForecastTableInfo.headCells,
          selectedColShow: tireForecastTableInfo.selectedColShow,
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        setPrintDataNotPDF({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
          rowsData: JSON.parse(JSON.stringify(formatProjectRemovalDate(filteredRowsData))),
          vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
          headCells: JSON.parse(JSON.stringify(tireForecastTableInfo.headCells)),
          selectedColShow: JSON.parse(JSON.stringify(tireForecastTableInfo.selectedColShow)),
          filterFieldsApplied: JSON.parse(JSON.stringify(filterFieldsAppliedValue)),
          filterApplied: true
        });

        filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
      }
      actionChanged(toggleHourMiles);
      setShowSpinner(false);
    });
    console.log(tableDataSettings,'tableDataSettings')
  };

  useEffect(() => {
    TireForecastResponseData(filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      changeDisplayColumns(displayColumns);
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  function actionChanged(eValue) {
    setToggleHourMiles(eValue);
    if (eValue === "Miles") {
      handleMilesClick();
    }
    if (eValue === "Hours") {
      setAverageHoursOfUsePerDay(averageHoursOfUsePerDay && averageHoursOfUsePerDay > 24 ? 24 : averageHoursOfUsePerDay);
      handleHoursClick();
    }
  }

  const handleMilesClick = () => {
    let arrOfCol = ["TotalHours", "ProjectedHours", "HoursLeft", "ProjectedCost", "ProjectedRemovalDate", "HoursPerTreadDepth"];
    const displayColSection = originalTireForecastTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });

    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalTireForecastTableData?.headCells;
    let showColumnsUpdated = tireForecastDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  const handleHoursClick = () => {
    let arrOfCol = ["TotalMiles", "ProjectedMiles", "MilesLeft", "ProjectedCostByMiles", "ProjectedRemovalDateByMiles", "MilesPerTreadDepth"];
    const displayColSection = originalTireForecastTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });
    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalTireForecastTableData?.headCells;
    let showColumnsUpdated = tireForecastDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });

    setFilterApplied(false);
    setTireForecastTableData(tableBeforeFilter);
    setOriginalTireForecastTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };

  const confirmApplyDateFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setSearchValue("");
    setFilterFieldsApplied(appliedFilter);
    TireForecastResponseData(true, appliedFilter);
    let originalVehicleDataStore = originalTireForecastTableData?.vehicleData;
    let originalRowsDataStore = originalTireForecastTableData?.rowsData;
    let originalTireVehicleMappedDataStore = originalTireForecastTableData?.tireVehicleMappedData;
    let filteredVehicleData = tireForecastDataSetter.filterTable(appliedFilter, originalVehicleDataStore);
    let filteredRowsData = tireForecastDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);
    let filteredVehicleMappedData = tireForecastDataSetter.setAxleDataOnUpdate(filteredVehicleData, originalTireVehicleMappedDataStore);


    setTireForecastTableData({
      ...tireForecastTableData,
      rowsData: formatProjectRemovalDate(filteredRowsData),
      vehicleData: filteredVehicleData,
      tireVehicleMappedData: filteredVehicleMappedData
    });
    setPrintData({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
      rowsData: formatProjectRemovalDate(filteredRowsData),
      vehicleData: filteredVehicleData,
      headCells: originalTireForecastTableData?.headCells,
      selectedColShow: originalTireForecastTableData?.selectedColShow
    });
    setPrintDataNotPDF({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
      rowsData: JSON.parse(JSON.stringify(formatProjectRemovalDate(filteredRowsData))),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(JSON.stringify(originalTireForecastTableData?.headCells)),
      selectedColShow: JSON.parse(JSON.stringify(originalTireForecastTableData?.selectedColShow))
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);



  };


  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    //  searchText === "" ? setFilterApplied(false) : setFilterApplied(true)
    let originalVehicleDataStore, originalRowsDataStore, originalTireVehicleMappedDataStore, filteredVehicleData, filteredRowsData, filteredVehicleMappedData;
    originalVehicleDataStore = originalTireForecastTableData?.vehicleData;
    originalRowsDataStore = tableBeforeFilter?.rowsData;
    originalTireVehicleMappedDataStore = tableBeforeFilter?.tireVehicleMappedData;

    filteredVehicleData = tireForecastDataSetter.search(originalVehicleDataStore, searchText);

    filteredVehicleMappedData = tireForecastDataSetter.setAxleDataOnUpdate(filteredVehicleData, originalTireVehicleMappedDataStore);

    filteredRowsData = tireForecastDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);

    setTireForecastTableData({
      ...tireForecastTableData,
      rowsData: formatProjectRemovalDate(filteredRowsData),
      vehicleData: filteredVehicleData,
      tireVehicleMappedData: filteredVehicleMappedData
    });
    setPrintData({
      ...printData,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
      rowsData: formatProjectRemovalDate(filteredRowsData),
      vehicleData: filteredVehicleData,
      headCells: originalTireForecastTableData?.headCells
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
      rowsData: JSON.parse(JSON.stringify(formatProjectRemovalDate(filteredRowsData))),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(JSON.stringify(originalTireForecastTableData?.headCells))
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalTireForecastTableData?.headCells;
    let showColumnsUpdated = tireForecastDataSetter.setDisplayColumnData(currentHeadCells, selected);

    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      selectedColShow: showColumnsUpdated
    });
  };

  const handleUpdateAverageHoursOfUse = (value) => {
    if (value === "" || Number(value) === 0 || Number(value) < 0) setAverageHoursOfUsePerDay(0);
    else if (toggleHourMiles === "Hours" && value <= 24) setAverageHoursOfUsePerDay(value);
    else if (toggleHourMiles === "" && value <= 24) setAverageHoursOfUsePerDay(value);
    else if (toggleHourMiles === "Miles") setAverageHoursOfUsePerDay(value);
    else setAverageHoursOfUsePerDay(0);
  };

  const handleUpdateAverageWeekDays = (value) => {
    if (value === "" || Number(value) === 0 || Number(value) < 0) setAverageWorkDaysPerWeek(0);
    else if (value <= 7) setAverageWorkDaysPerWeek(value);
    else setAverageWorkDaysPerWeek(0);
  };

  const handleSteeringWheelsForecastType = (event) => {
    if (event.target.value === "treadWearRate") {
      setSteeringWheelsFixedRemovalHours(14000);
    }
    setSteeringWheelsForecastType(event.target.value);
  };

  const handleDrivingWheelsForecastType = (event) => {
    if (event.target.value === "treadWearRate") {
      setDrivingWheelsFixedRemovalHours(14000);
    }
    setDrivingWheelsForecastType(event.target.value);
  };

  const handleSteeringWheelsFixedRemovalHours = (value) => {
    setSteeringWheelsFixedRemovalHours(value);
  };

  const handleDrivingWheelsFixedRemovalHours = (value) => {
    setDrivingWheelsFixedRemovalHours(value);
  };

  const handleRemoveAt = (value) => {
    setRemoveAt(value);
  };

  const handleExcludeUsedTires = (event) => {
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
  };

  const handleUpdateReport = () => {
    setTableDataSettings ({
      ...tableDataSettings,
      averageHoursOfUsePerDay,
    averageWorkDaysPerWeek,
    steeringWheelsForecastType,
    drivingWheelsForecastType,
    removeAt,
    steeringWheelsFixedRemovalHours,
    drivingWheelsFixedRemovalHours,
    excludeUsedTires,
    })
    TireForecastResponseData(filterApplied, filterFieldsApplied);
  };

  return (
    <div className="container fleet-root-class">
      <ByTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-compound-container">
          <Card className="by-compound-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters-tire-forecast">
                <Grid container xs={10} spacing={2}>
                  <Grid item xs={3}>
                    <div className="search-table-container">
                      <form noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                        </InputLabel>
                        <TextField
                          id="input-with-icon-textfield"
                          variant="outlined"
                          placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                          value={searchValue}
                          onChange={searchTableData}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                              </InputAdornment>
                            )
                          }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </form>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="display-columns-container-tire-forecast" id="dashboard-display-column">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                      </InputLabel>
                      <MultiSelect
                        options={displayColOptions}
                        value={displayColumns}
                        onChange={changeDisplayColumns}
                        overrideStrings={{
                          selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                          selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="display-columns-container" id="dashboard-display-column">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                      </InputLabel>
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="manage-vehicle-action-dropdown"
                        variant="outlined"
                        value={toggleHourMiles}
                        onChange={(e) => actionChanged(e.target.value)}
                        style={{width: "50%"}}
                      >
                        <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                        <option value={"Miles"}>{toggleMile}</option>
                      </Select>
                    </div>
                  </Grid>
                </Grid>
                  
                </div>
                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                  isVehicleReport={true}
                />
              </div>
              <Grid container item xs={6} spacing={2} style={{borderStyle:'dashed', borderColor:'#07469e87',borderWidth:'thin',padding:5, marginBottom:15 }}>
                    <Grid item xs={2}>
                      <div className="tire-forecast-dropdown">
                        <InputLabel className="filter-table-label">
                          {(!toggleHourMiles || toggleHourMiles === "Hours") && (
                            <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.AvgHoursperDay}</span>
                          )}
                          {toggleHourMiles && toggleHourMiles === "Miles" && (
                            <span className="table-toolbar-label-text">
                              {globalizationMapping.MANAGEVEHICLE.KM === toggleMile
                                ? globalizationMapping.REPORTCOMMONWORDS.AvgKMsperDay
                                : globalizationMapping.REPORTCOMMONWORDS.AvgMilesperDay}
                            </span>
                          )}
                        </InputLabel>
                        <TextField variant="outlined" value={averageHoursOfUsePerDay} onChange={(e) => handleUpdateAverageHoursOfUse(commonUtils.minmaxInt(e.target.value, 0, 13))} type="number" inputProps={{ maxLength: 50 }}/>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="tire-forecast-dropdown">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.AvgDaysperWeek}</span>
                        </InputLabel>
                        <TextField variant="outlined" value={averageWorkDaysPerWeek} onChange={(e) => handleUpdateAverageWeekDays(commonUtils.minmaxInt(e.target.value, 0, 7))} type="number" inputProps={{ maxLength: 50 }}/>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="tire-scrap-type">
                          <InputLabel className="filter-table-label">
                            <span className="table-toolbar-label-text">
                              Remove At
                            </span>
                          </InputLabel>
                          <TextField
                              type="number"
                              className="enter-detail-input-box"
                              variant="outlined"
                              value={removeAt}
                              onChange={(e) => handleRemoveAt(commonUtils.minmaxInt(e.target.value, 0, 999999))} 
                              inputProps= {{maxLength: 3 }} 
                              min
                          />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="tire-forecast-dropdown">
                        <div className="exclude-used-tires-tire-forecast">
                          <FormControlLabel
                            control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                            label={globalizationMapping.GRAPH.ExcludeUsedTires}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                        <button variant="contained" color="primary" className="update-button" onClick={handleUpdateReport} disabled={noData === true ? true : false}>
                          {globalizationMapping.INSPECT.UPDATE}
                        </button>
                    </Grid>
                    <Grid container item className={classes.gridContainer} xs={12} spacing={1}>
              <Grid item sm={3} xs={3} className={classes.gridItem}>
                <InputLabel className="filter-table-label">
                  <span className="table-toolbar-label-text">
                    {filterSectionWords.SteeringWheelsForecast}
                  </span>
                </InputLabel>
                <Select
                  native
                  variant="outlined"
                  value={steeringWheelsForecastType}
                  onChange={handleSteeringWheelsForecastType}
                  IconComponent={ExpandMoreStyledIcon}
                >
                  <option value="treadWearRate">
                    {filterSectionWords.TreadWearRate}
                  </option>
                  <option value="fixedRemovalHours">
                    {filterSectionWords.FixedRemovalHours}
                  </option>
                </Select>
              </Grid>
              <Grid item sm={3} xs={3} className={classes.gridItem}>
                  <InputLabel className="filter-table-label">
                    <span className="table-toolbar-label-text" style={{color: steeringWheelsForecastType === "fixedRemovalHours" ? 'black' : commonUtils.disabledColor }}>
                      {filterSectionWords.FixedRemovalHours}
                    </span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    value={steeringWheelsFixedRemovalHours}
                    onChange={(e) => handleSteeringWheelsFixedRemovalHours(commonUtils.minmaxInt(e.target.value, 0, 999999))}
                    type="number"
                    disabled={steeringWheelsForecastType !== "fixedRemovalHours"}
                  />
              </Grid>
            </Grid>
            <Grid container item className={classes.gridContainer} xs={12} spacing={1}>
              <Grid item sm={3} xs={3} className={classes.gridItem}>
                <InputLabel className="filter-table-label">
                  <span className="table-toolbar-label-text">
                    {filterSectionWords.DrivingWheelsForecast}
                  </span>
                </InputLabel>
                <Select
                  native
                  variant="outlined"
                  value={drivingWheelsForecastType}
                  onChange={handleDrivingWheelsForecastType}
                  IconComponent={ExpandMoreStyledIcon}
                >
                  <option value="treadWearRate">
                    {filterSectionWords.TreadWearRate}
                  </option>
                  <option value="fixedRemovalHours">
                    {filterSectionWords.FixedRemovalHours}
                  </option>
                </Select>
              </Grid>
              <Grid item sm={3} xs={3} className={classes.gridItem}>
                  <InputLabel className="filter-table-label">
                    <span className="table-toolbar-label-text" style={{color: drivingWheelsForecastType === "fixedRemovalHours" ? 'black' : commonUtils.disabledColor }}>
                      {filterSectionWords.FixedRemovalHours}
                    </span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    value={drivingWheelsFixedRemovalHours}
                    onChange={(e) => handleDrivingWheelsFixedRemovalHours(commonUtils.minmaxInt(e.target.value, 0, 999999))}
                    type="number"
                    disabled={drivingWheelsForecastType !== "fixedRemovalHours"}
                  />
              </Grid>
            </Grid>
            </Grid>

              <div className="data-summary-table">
                <TireForecastTable tableData={tireForecastTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} keys={keys} tableData={printData} noGraph={true} tableDataSettings={tableDataSettings} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} keys={keys} noGraph={true} tableDataSettings={tableDataSettings}/>
      <EmailExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tireForecast={true} noGraph={true} />

      <TireForecastFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />

    </div>
  );
};

export default TireForecast;
