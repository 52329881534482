import React, { useEffect, useState, useContext } from "react";
import "./ByTireSize.css";
import { Card, CardContent, InputLabel, TextField, InputAdornment, FormControlLabel, Checkbox, Grid, Select } from "@material-ui/core";
import ScrapTiresNavigation from "../../../../scrap-tires/scrap-tires-navigation/components/ScrapTiresNavigation";
import ByTireSizeDataSetter from "../../../../../services/ByTireSizeUtils";
import ByTireSizeGraph from "../graph/ByTireSizeGraph";
import ByTireSizeTable from "../table/ByTireSizeTable";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByTireSizeFilter from '../filter/ByTireSizeFilter.js'

const ByTireSize = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const globalizationMapping = new Globalization();
  const tiresService = new TiresService();
  const byTireSizeDataSetter = new ByTireSizeDataSetter();
  const commonUtils = new CommonUtils();
  const [showSpinner, setShowSpinner] = useState(true);
  const [byTireSizeData, setByTireSizeData] = useState();
  const [originalByTireSizeData, setOriginalByTireSizeData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byTireSizeData);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [tableDataSettings, setTableDataSettings] = useState({
    excludeUsedTires: false,
  });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  // filter options driver
  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" },

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  useEffect(() => {
    ByTireSizeResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, excludeUsedTires, filterApplied, filterFieldsApplied]);

  const ByTireSizeResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Scrap_Tire_By_Tire_Type",
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "ManufacturerName"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }

      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        CompoundName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let byTireSizeInfo = byTireSizeDataSetter.setData(data);
      let filterOptionsInfo = byTireSizeDataSetter.setFilterOptionsData(filterOptions, byTireSizeInfo.rowsData);
      setByTireSizeData(byTireSizeInfo);
      setOriginalByTireSizeData(byTireSizeInfo);
      setTableBeforeFilter(byTireSizeInfo);
      byTireSizeInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(byTireSizeInfo.headCells);
      setDisplayColumns(byTireSizeInfo.headCells);
      setShowColumns(byTireSizeInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: "Scrap Tires By Tire Type",
        rowsData: byTireSizeInfo.rowsData,
        headCells: byTireSizeInfo.headCells,
        avgValues: byTireSizeDataSetter.avgValues(byTireSizeInfo.rowsData),
        selectedColShow: byTireSizeInfo.selectedColShow
      });
      setShowSpinner(false);
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byTireSizeInfo.rowsData;
        let filteredData = byTireSizeDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setByTireSizeData({
          ...byTireSizeInfo,
          rowsData: filteredData,
          avgValues: byTireSizeDataSetter.avgValues(filteredData)
        });
        setOriginalByTireSizeData({
          ...byTireSizeInfo,
          rowsData: filteredData,
          avgValues: byTireSizeDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byTireSizeDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };



  const actionChanged = (value) => {
    let arrOfCol = [];
    if (value === "miles") arrOfCol = ["AverageHours"];
    else arrOfCol = ["AverageMiles"];

    const displayColSection = byTireSizeData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });
    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalByTireSizeData?.headCells;
    let showColumnsUpdated = byTireSizeDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);
    setByTireSizeData({
      ...byTireSizeData,
      selectedColShow: showColumnsUpdated
    });

    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;
    tableDataStore = originalByTireSizeData?.rowsData;
    filteredData = byTireSizeDataSetter.search(tableDataStore, searchText);
    setByTireSizeData({
      ...byTireSizeData,
      rowsData: filteredData,
      avgValues: byTireSizeDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byTireSizeDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalByTireSizeData?.headCells;
    let showColumnsUpdated = byTireSizeDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

  setCurrentFilter(toSetCurrentFilter);
  if (appliedFilter === filterOptions.categoryData) {
    setFilterApplied(false);
  } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    ByTireSizeResponseData(excludeUsedTires, true, appliedFilter);

    let originalTableDataStore = originalByTireSizeData?.rowsData;
    let filteredData = byTireSizeDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setByTireSizeData({
      ...byTireSizeData,
      rowsData: filteredData,
      avgValues: byTireSizeDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byTireSizeDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);

  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });
    setFilterApplied(false);
    if (tableBeforeFilter) {
      tableBeforeFilter.selectedColShow = printData?.selectedColShow;
    }
    setByTireSizeData(tableBeforeFilter);
    setOriginalByTireSizeData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      selectedColShow: printData?.selectedColShow,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setOnlyFilterApplied(false);
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setNoData(false);
  };

  const handleExcludeUsedTires = (event) => {
    setTableDataSettings({ 
      ...tableDataSettings,
      excludeUsedTires: event.target.checked });
    setExcludeUsedTires(event.target.checked);

    ByTireSizeResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div>
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-tire-size-container">
          <Card className="by-tire-size-card">
            <CardContent className="graph-card">
              <ByTireSizeGraph byTireSizeGraphData={byTireSizeData} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value={"hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"miles"}> {toggleMile}</option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>

              <div className="by-tire-size-table">
                <ByTireSizeTable tableData={byTireSizeData} showColumns={showColumns} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tableDataSettings={tableDataSettings}/>
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tableDataSettings={tableDataSettings}/>
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <ByTireSizeFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByTireSize;
