import React, { useEffect, useState, useContext } from "react";
import "./InServiceTireCostSummary.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import InServiceTireCostSummaryDataSetter from "../../../../../services/InServiceTireCostSummaryUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import InServiceTireCostSummaryTable from "../table/InServiceTireCostSummaryTable";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import InServiceTireCostSummaryGraph from "../graph/InServiceTireCostSummaryGraph";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import InServiceTireCostSummaryFilter from "../filter/InServiceTireCostSummaryFilter.js";

const InServiceTireCostSummary = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const commonUtils = new CommonUtils();
  const inServiceTireCostSummaryDataSetter = new InServiceTireCostSummaryDataSetter();

  const [inServiceTireCostSummaryTableData, setInServiceTireCostSummaryTableData] = useState();
  const [originalInServiceTireCostSummaryTableData, setOriginalInServiceTireCostSummaryTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(inServiceTireCostSummaryTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [tableDataSettings, setTableDataSettings] = useState({
    excludeUsedTires: false,
    tireScrapType: "AllTire"
  });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  useEffect(() => {
    InServiceTireCostSummaryResponseData(tireScrapType, excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const InServiceTireCostSummaryResponseData = (tireScrapTypeValue, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      locationIds: locationIds,
      reportName: "InService_Cost_Summary",
      reportOption: "",
      tireScrapType: tireScrapTypeValue,
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "ManufacturerName"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" }
      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let inServiceTireCostSummaryTableInfo = inServiceTireCostSummaryDataSetter.setData(data);
      let filterOptionsInfo = inServiceTireCostSummaryDataSetter.setFilterOptionsData(filterOptions, inServiceTireCostSummaryTableInfo.rowsData);
      
      setInServiceTireCostSummaryTableData(inServiceTireCostSummaryTableInfo);
      setOriginalInServiceTireCostSummaryTableData(inServiceTireCostSummaryTableInfo);
      setTableBeforeFilter(inServiceTireCostSummaryTableInfo);
      inServiceTireCostSummaryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(inServiceTireCostSummaryTableInfo.headCells);
      setDisplayColumns(inServiceTireCostSummaryTableInfo.headCells);
      setShowColumns(inServiceTireCostSummaryTableInfo.selectedColShow);
      setPrintData({
        reportType: "Tires in Services : ",
        reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.InServiceTireCostSummary,
        rowsData: inServiceTireCostSummaryTableInfo.rowsData,
        headCells: inServiceTireCostSummaryTableInfo.headCells,
        avgValues: inServiceTireCostSummaryTableInfo.avgValues,
        selectedColShow: inServiceTireCostSummaryTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      setShowSpinner(false);
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = inServiceTireCostSummaryTableInfo.rowsData;
        let filteredData = inServiceTireCostSummaryDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        //
        setInServiceTireCostSummaryTableData({
          ...inServiceTireCostSummaryTableInfo,
          rowsData: filteredData,
          avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData)
        });
        setOriginalInServiceTireCostSummaryTableData({
          ...inServiceTireCostSummaryTableInfo,
          rowsData: filteredData,
          avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    //
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const actionChanged = (action) => {
    if (action === "Miles") {
      handleMilesClick("AverageHours", "AverageProjHours", "CostPerHourProj");
    } else {
      handleMilesClick("AverageMiles", "AverageProjMiles", "CostPerMileProj");
    }
  };

  const handleMilesClick = (...avoidStr) => {
    let inServiceTireCostSummaryTableInfo = inServiceTireCostSummaryTableData;

    inServiceTireCostSummaryTableInfo.headCells.map((item) => {
      let flag = true;
      avoidStr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      inServiceTireCostSummaryTableInfo.selectedColShow[item.value] = flag;
    });

    setInServiceTireCostSummaryTableData(inServiceTireCostSummaryTableInfo);
    setTableBeforeFilter(inServiceTireCostSummaryTableInfo);
    inServiceTireCostSummaryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = inServiceTireCostSummaryTableInfo.headCells.filter((item) => {
      let flag = true;
      avoidStr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });
    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(inServiceTireCostSummaryTableInfo.selectedColShow);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireType,
      rowsData: inServiceTireCostSummaryTableInfo.rowsData,
      headCells: inServiceTireCostSummaryTableInfo.headCells,
      avgValues: inServiceTireCostSummaryDataSetter.avgValues(inServiceTireCostSummaryTableInfo.rowsData),
      selectedColShow: inServiceTireCostSummaryTableInfo.selectedColShow
    });
    setShowSpinner(false);
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;

    if (showColumns?.AverageHours) {
      tableDataStore = originalInServiceTireCostSummaryTableData?.rowsData.map(({ AverageMiles, AverageProjMiles, CostPerMileProj, ...rest }) => {
        return rest;
      });
    } else {
      tableDataStore = originalInServiceTireCostSummaryTableData?.rowsData.map(({ AverageHours, AverageProjHours, CostPerHourProj, ...rest }) => {
        return rest;
      });
    }
    filteredData = inServiceTireCostSummaryDataSetter.search(tableDataStore, searchText);
    //
    setInServiceTireCostSummaryTableData({
      ...inServiceTireCostSummaryTableData,
      rowsData: filteredData,
      avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Tires In Service : ",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.InServiceTireCostSummary,
      rowsData: filteredData,
      headCells: originalInServiceTireCostSummaryTableData?.headCells,
      avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData),
      selectedColShow: originalInServiceTireCostSummaryTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalInServiceTireCostSummaryTableData?.headCells;
    let showColumnsUpdated = inServiceTireCostSummaryDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setFilterApplied(false);
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });
    setInServiceTireCostSummaryTableData(tableBeforeFilter);
    setOriginalInServiceTireCostSummaryTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };
   setCurrentFilter(toSetCurrentFilter);

    setSearchValue("");
    setFilterApplied(true);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}
    let originalTableDataStore = originalInServiceTireCostSummaryTableData?.rowsData;
    let filteredData = inServiceTireCostSummaryDataSetter.filterTable(appliedFilter, originalTableDataStore);
    
    setInServiceTireCostSummaryTableData({
      ...inServiceTireCostSummaryTableData,
      rowsData: filteredData,
      avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: inServiceTireCostSummaryDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);

    InServiceTireCostSummaryResponseData(tireScrapType, excludeUsedTires, true, appliedFilter);
  };

  const handleUpdateTireScrapType = (event) => {
    setTableDataSettings({
      ...tableDataSettings,
      tireScrapType: event.target.value
    })
    setTireScrapType(event.target.value);
    InServiceTireCostSummaryResponseData(event.target.value, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setTableDataSettings({
      ...tableDataSettings,
      excludeUsedTires: event.target.checked
    })
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
    InServiceTireCostSummaryResponseData(tireScrapType, event.target.checked, filterApplied, filterFieldsApplied);
    
  };

  return (
    <div className="container">
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="in-service-tire-cost-container">
          <Card className="in-service-tire-cost-card">
            <CardContent>
              <CardContent className="graph-card">
                <InServiceTireCostSummaryGraph inServiceTireCostSummaryGraphData={inServiceTireCostSummaryTableData?.rowsData} />
              </CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      onChange={(e) => actionChanged(e.target.value)}
                    >
                      <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={4} md={3} lg={2} xl={2}>
                  <div className="" id="tire-scrap-type-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.GRAPH.TireScrapType}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      value={tireScrapType} 
                      name="tire scrap type" 
                      variant="outlined" 
                      onChange={handleUpdateTireScrapType}
                      >
                      <option value="AllTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption1} </option>
                      <option value="Repair">{globalizationMapping.REPORTCOMMONWORDS.TypeOption2} </option>
                      <option value="Retread">{globalizationMapping.REPORTCOMMONWORDS.TypeOption3} </option>
                      <option value="NewTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption4} </option>
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={4} md={4} lg={3} xl={2}>
                  <div className="select-drop-down-container exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <InServiceTireCostSummaryTable tableData={inServiceTireCostSummaryTableData} showColumns={showColumns} filterApplied={filterApplied} />
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} tableDataSettings={tableDataSettings}/>
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} tableDataSettings={tableDataSettings}/>
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />

      <InServiceTireCostSummaryFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default InServiceTireCostSummary;
