import React from "react";
import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../../common-modal/CommonPopup.css";
import { getAccountNames, getLocationNames } from "../../../../services/AccountInfoHandler";
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";

const commonUtils = new CommonUtils();
pdfMake.vfs = pdfFonts.pdfMake.vfs;
class RemovedTirePDF extends React.Component {
  globalization = new Globalization();

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  async PDFMake() {
    return this.makeTableData();
  }

  async generateRemovedTirePDF() {
    let docDefinition = {
      content: []
    };

    let n = 0;
    let maxLimit = 4;
    for (let index = 0; index < this.props.tableData.rowsData.length; index++) {
      let eachData = this.props.tableData.rowsData[index];
      let recordNum = index;
      let recordDetails = {
        table: {
          widths: ["*"],
          margin: [20, 0, 20, 0],
          body: [
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                color: "#000",
                fillColor: "#E5F0FF",
                alignment: "left",
                columns: [
                  {
                    text: "Record No. ",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: ++recordNum
                  },
                  {
                    text: "Account Name : ",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.ParentAccount
                  },
                  {
                    text: "Location : ",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.Location
                  },
                  {
                    text: "Inspected By : ",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.InspectedBy
                  },
                  {
                    text: "Inspection ID/ Record ID : ",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.InspectedBy
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "             ",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ]
              }
            ]
          ]
        }
      };
      if (index > 0) {
        recordDetails.table.body[0][0].pageBreak = "before";
      }

      let column1 = { columns: [] };
      let imgArray = [];
      if (eachData.RemoveTireImages !== null) {
        imgArray = eachData.RemoveTireImages.split(",");
        let tireImage;
        for (const imageUrl of imgArray) {
          if (imageUrl !== "") {
            tireImage = await this.getBase64ImageFromURL(imageUrl, false);
            column1.columns.push({
              image: tireImage,
              width: 180,
              height: 130
            });
          }
        }
      }

      let tireDetails = {
        table: {
          widths: ["*"],
          margin: [40, 0, 20, 0],
          body: [
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "            ",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  "",
                  ""
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Tire Details",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  "",
                  ""
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Serial No:" + eachData.SerialNo,
                    bold: true,
                    width: 140
                  },
                  {
                    text: "Manufacturer:" + eachData.ManufacturerName,
                    bold: true,
                    width: 170
                  },
                  {
                    text: "Size : " + eachData.SizeName,
                    bold: true,
                    width: 130
                  },
                  {
                    text: "Type : " + eachData.TypeName,
                    bold: true,
                    width: 150
                  },
                  {
                    text: "Compound : " + eachData.CompoundName,
                    bold: true,
                    width: 140
                  },
                  {
                    text: "Rating : " + eachData.RatingName,
                    bold: true,
                    width: 140
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Hours: " + eachData.currentHours,
                    bold: true,
                    width: 140
                  },
                  {
                    text: "Distance : " + eachData.currentMiles,
                    bold: true,
                    width: 170
                  },
                  {
                    text: "% Worn : " + eachData.PercentageWorn,
                    bold: true,
                    width: 130
                  },
                  {
                    text: "Tread (Out,In)/Org : " + eachData.TreadDepth,
                    bold: true,
                    width: 150
                  },
                  {
                    text: "Cost Per hour : " + eachData.CostPerHour,
                    bold: true,
                    width: 140
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, true],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Id: " + eachData.VehId,
                    bold: true,
                    width: 140
                  },
                  {
                    text: "Asset No: " + eachData.AssetNumber,
                    bold: true,
                    width: 170
                  },
                  {
                    text: "Make/Model : " + eachData.VehicleSpec,
                    bold: true,
                    width: 130
                  },
                  {
                    text: "Position : " + eachData.WheelPosition,
                    bold: true,
                    width: 150
                  },
                  {
                    text: "Removal Date : " + eachData.RemovalDate,
                    bold: true,
                    width: 140
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, true],
                alignment: "left",
                columns: [
                  {
                    text: "RemovalReason : " + eachData.RemovalReason,
                    bold: true,
                    width: 140
                  },
                  {
                    text: "Value Lost: " + eachData.MoneyLost,
                    bold: true,
                    width: 170
                  },
                  {
                    text: "Comments: " + eachData.Comments,
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  ""
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Graph",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  ""
                ]
              }
            ]
          ]
        },
        layout: {
          hLineColor: "grey"
        }
      };

      let column2 = { columnGap: 10, columns: [] };
      let canvasElm = document.getElementsByClassName("chartjs-render-monitor");
      if (canvasElm && canvasElm.length > 0) {
        let img = new Image();
        // writing canvas images to pdf
        for (let i = n; i <= maxLimit; i++) {
          if (canvasElm[i] !== undefined) {
            img.src = canvasElm[i].toDataURL();
            let imgData = canvasElm[i].toDataURL();

            column2.columns.push({
              image: imgData,
              width: 150,
              height: 130
            });
          }
        }
        n = n + 5;
        maxLimit = maxLimit + 5;
      }
      docDefinition.content.push(recordDetails);
      docDefinition.content.push(column1);
      docDefinition.content.push(tireDetails);
      docDefinition.content.push(column2);
    }
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [[" ", " ", " "]]
      },
      layout: "noBorders"
    });
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: "Total Records : " + this.props.tableData.rowsData.length,
              fontSize: 7
            }
          ]
        ]
      },
      layout: "noBorders",
    });
    return docDefinition.content;
  }

  getBase64ImageFromURL(url, isLogo) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        let canvas = document.createElement("canvas");
        if (!isLogo) {
          canvas.width = 200;
          canvas.height = 150;
        } else {
          canvas.width = 90;
          canvas.height = 25;
        }

        let ctx = canvas.getContext("2d");
        let wrh = img.width / img.height;
        let newWidth = canvas.width;
        let newHeight = newWidth / wrh;
        if (newHeight > canvas.height) {
          newHeight = canvas.height;
          newWidth = newHeight * wrh;
        }
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        img.src = canvas.toDataURL("image/png");
        let dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  /** Doc definition */
  async createDocumentDefinition() {
    const baseDocDefinition = {
      pageSize: "A4",
      pageMargins: [20, 90, 20, 55],
      pageOrientation: "landscape",
      content: []
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.header = await this.addPdfMakeHeader();
    docDefinition.footer = this.addPdfMakeFooter();
    docDefinition.content.push(await this.generateRemovedTirePDF());
    const defaultFont = {defaultStyle: {
      font: 'Barlow'
    }}
    docDefinition.defaultStyle = defaultFont;
    return docDefinition;
  }

  /** header body and footer */
  async addPdfMakeHeader() {

    return (_currentPage, _pageCount) => {
      return {
        margin: [20, 20, 20, 0],
        stack: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    
                    color: "white",
                    fillColor: "#1250B0",
                    alignment: "right",
                    columns: [
                      {
                        svg: commonUtils.EmTrackSVGLogo,
                        fit: [50, 80],
                        width: "5%",
                        margin: [0, 2, 0, 2],
                        alignment: "left"
                      },
                      {
                        text: " " + commonUtils.pdfReportName(this.props.tableData.reportName),
                        bold: true,
                        alignment: "center",
                        fontSize: 14,
                        margin: [0, 10, 0, 2]
                      },
                      {
                        svg: commonUtils.GoodyearSVGLogo,
                        alignment: "right",
                        width: "10%",
                        fit: [90, 150]
                      },
                    ]
                  }
                ]
              ]
            }
          },
          {
            table: {
              widths: ["*"],
              margin: [20, 0, 20, 0],
              body: [
                [
                  {
                    
                    fontSize: 10,
                    border: [false, false, false, false],
                    color: "#074BA7",
                    fillColor: "#E5F0FF",
                    alignment: "left",
                    columns: [
                      {
                        text: this.globalization.REPORTTABLE.DATE + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: Moment(new Date()).format("DD/MM/YYYY"),
                        bold: true
                      },
                      {
                        text: this.globalization.MANAGEPARENTACCOUNT.ACCOUNT + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: getAccountNames().toString(),
                        bold: true
                      },
                      {
                        text: this.globalization.HEADER.LOCATION + ": \u200B\t ",
                        width: "auto"
                      },
                      {
                        text: getLocationNames().toString(),
                        bold: true,
                        width: "auto"
                      }
                    ]
                  }
                ]
              ]
            }
          }
        ]
      };
    };
  }

  addPdfMakeFooter = () => {
      let footerText = "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
    return (currentPage, pageCount) => {
      // you can apply any logic and return any valid pdfmake element
      return {
        margin: [20, 0, 20, 0],
        border: [false, false, false, false],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                color: "white",
                fillColor: "#1250B0",
                alignment: "left",
                columns: [
                  {
                    text: footerText,
                    alignment: "left",
                    width: "40%",
                    margin: [0, 13, 0, 0],
                    bold: true
                  },
                  {
                    text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                    alignment: "right",
                    margin: [0, 13, 0, 0],
                    fontWeight: 400
                  }
                ]
              }
            ]
          ]
        }
      };
    };
  };

  async makeTableData() {
    // -- Create a base document template for the reports.
    const docDefinition = await this.createDocumentDefinition();
    return pdfMake.createPdf(docDefinition);
  }
  render() {
    return <div></div>;
  }
}

export default RemovedTirePDF;
