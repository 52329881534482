import React from "react";
import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../../common-modal/CommonPopup.css";
import Base64Img from "../fleet-survey-pdf/FleetImageUtils";
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";
const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();

pdfMake.vfs = pdfFonts.pdfMake.vfs;
class TireForecastPDF extends React.Component {

  tableColumnWidths = [];
  globalization = new Globalization();

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  /** cell making */
  makeCell = (content, headerOrData, options = {}, rowIndex = -1) => {
    let count = this.getCount();

    const makeCellFontSize = () => {
      if (headerOrData === "data") {
        if (count >= 12) {
          return 6;
        } else {
          return 9;
        }
      } else {
        if (count >= 12) {
          return 6;
        } else {
          return this.fontSize;
        }
      }
    };

    const makeCellFillColor = () => {
      if (headerOrData === "data") {
        if (rowIndex % 2) {
          return "#FAFAFB";
        } else {
          return "#FFFFFF";
        }
      } else if (headerOrData === "total") {
        return "#E6ECF6";
      } else if (headerOrData === "low") {
        return "#ccf2db";
      } else if (headerOrData === "avg") {
        return "#ffffaf";
      } else if (headerOrData === "high") {
        return "#ffe0a9";
      } else if (headerOrData === "vhigh") {
        return "#ffc4c6";
      } else {
        return "#1250B0";
      }
    };

    return Object.assign(
      {
        text: content,
        border: [false, false, false, false],
        borderColor:
          headerOrData === "data" || headerOrData === "low" || headerOrData === "avg" || headerOrData === "high" || headerOrData === "vhigh"
            ? ["#E0E0E0", "#E0E0E0", "#E0E0E0", "#E0E0E0"]
            : ["#456CAD", "#456CAD", "#456CAD", "#456CAD"],
        fontSize: makeCellFontSize(),
        
        fillColor: makeCellFillColor(),
        color: headerOrData === "data" || headerOrData === "total" ? "#2E2E2E" : "white"
      },
      options
    );
  };
  //-- Format the table cells for presentation.
  thl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: true, alignment: "left", fontSize: 11, padding: 10 }, options, rowIndex));
  };
  tdl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: false, alignment: "left", fontSize: 7 }, options, rowIndex));
  };
  fontSize = 8;

  getCount = () => {
    let count = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    return count;
  };
  async PDFMake() {
    return this.makeTableData();
  }

  async generateRemovedTirePDF() {
    let docDefinition = {
      content: []
    };

    let tireTable;
    for (let index = 0; index < this.props.tableData.vehicleData.length; index++) {
      let eachData = this.props.tableData.vehicleData[index];
      tireTable = {
        width: "auto",
        table: {
          headerRows: 1,
          widths: this.tableColumnWidths,
          body: this.tableBody(index)
        }
      };

      let column1 = { columns: [] };
      let recordNum = index;
      let recordDetails = {
        table: {
          widths: ["*"],
          margin: [20, 0, 20, 10],
          body: [
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                color: "#074BA7",
                fillColor: "#E5F0FF",
                alignment: "left",
                columns: [
                  {
                    text: this.globalization.REPORTTABLE.DATE + ": \u200B\t",
                    width: "8%"
                  },
                  {
                    text: "  " + Moment(new Date()).format("DD/MM/YYYY"),
                    width: "15%",
                    bold: true
                  },
                  {
                    text: this.globalization.REPORTTABLE.ACCOUNTNAME + ": \u200B\t",
                    width: "10%"
                  },
                  {
                    text: eachData.ParentAccountName,
                    width: "30%",
                    bold: true
                  },
                  {
                    text: this.globalization.HEADER.LOCATION + ": \u200B\t",
                    width: "10%"
                  },
                  {
                    text: eachData.LocationName,
                    width: "30%",
                    bold: true
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                color: "#074BA7",
                fillColor: "#E5F0FF",
                alignment: "left",
                columns: [
                  {
                    text: "Record No. ",
                    width: "8%"
                  },
                  {
                    text: ++recordNum,
                    width: "15%",
                    bold: true
                  },
                  {
                    text: "Inspected By : ",
                    width: "10%"
                  },
                  {
                    text: eachData.InspectionName,
                    bold: true,
                    width: "30%"
                  },
                  {
                    text: "Inspection ID : ",
                    width: "10%"
                  },
                  {
                    text: eachData.InspectionOrderId,
                    bold: true,
                    width: "30%"
                  }
                ]
              }
            ]
          ]
        }
      };
      if (index > 0) {
        recordDetails.table.body[0][0].pageBreak = "before";
      }

      let tireDetails = {
        width: "50%",
        alignment: "center",
        table: {
          // margin:[100,100,100,100],
          body: [
            [
              {
                
                fontSize: 10,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Details",
                    bold: true
                    // width: "auto"
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Id:",
                    width: 70
                  },
                  {
                    text: eachData.VehicleId,
                    width: 100
                  },
                  {
                    text: "Make/Model:",
                    width: 70
                  },
                  {
                    text: eachData.Manufacturer + "/" + eachData.ModelName,
                    width: 150
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Type : ",
                    width: 70
                  },
                  {
                    text: eachData.TypeName,
                    width: 100
                  },
                  {
                    text: "Asset No : ",
                    width: 70
                  },
                  {
                    text: eachData.AssetNumber,
                    width: 70
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text:
                      "Hours/" +
                      (JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
                        ? globalizationMapping.MANAGEVEHICLE.KM
                        : globalizationMapping.MANAGEVEHICLE.MILES) +
                      " : ",
                    width: 70
                  },
                  {
                    text: eachData.CurrentHours + "/" + eachData.CurrentMiles,
                    width: 100
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Operation Parameters",
                    bold: true
                    // width: "auto"
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Cutting : ",
                    width: 70
                  },
                  {
                    text: eachData.CuttingName,
                    width: 100
                  },
                  {
                    text: "Speed/Heat : ",
                    width: 70
                  },
                  {
                    text: eachData.SpeedName,
                    width: 150
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Loading Req: ",
                    width: 70
                  },
                  {
                    text: eachData.LoadingRequirement,
                    width: 100
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "            ",
                    width: "auto"
                  },
                  "",
                  "",
                  "",
                  ""
                ]
              }
            ]
          ]
        }
      };
      let imageString = eachData.VehJsonData;
      let str;
      if (imageString !== null) {
        let mapObj = {
          GREEN: Base64Img.GREEN,
          RED: Base64Img.RED,
          YELLOW: Base64Img.YELLOW,
          ORANGE: Base64Img.ORANGE,
          EMPTY: Base64Img.EMPTY,
          LEGEND: Base64Img.LEGEND,
          AXLE1: Base64Img.AXLE1,
          AXLE2: Base64Img.AXLE2,
          AXLE3: Base64Img.AXLE3,
          AXLE4: Base64Img.AXLE4,
          AXLE5: Base64Img.AXLE5,
          AXLE6: Base64Img.AXLE6,
          AXLE7: Base64Img.AXLE7,
          AXLE8: Base64Img.AXLE8,
          AXLE9: Base64Img.AXLE9
        };
        str = imageString.replace(/GREEN|RED|YELLOW|ORANGE|EMPTY|LEGEND|AXLE1|AXLE2|AXLE3|AXLE4|AXLE5|AXLE6|AXLE7|AXLE8|AXLE9/gi, function (matched) {
          return mapObj[matched];
        });
      }
      if (str !== undefined) {
        let obj;
        const strToJS = new Function("return " + str);
        obj = strToJS();
        column1.columns.push(obj);
      }
      column1.columns.push(tireDetails);

      let column4 = { columnGap: 10, columns: [] };
      docDefinition.content.push(recordDetails);
      docDefinition.content.push(column1);
      docDefinition.content.push(tireTable);
      docDefinition.content.push(column4);
    }
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [" ", " ", " "],
          [" ", " ", " "],
        ]
      },
      layout: "noBorders"
    });
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: "Total Records : " + this.props.tableData.rowsData.length,
              fontSize: 7
            }
          ]
        ]
      },
      layout: "noBorders",
    });

    let applyFilterTable = false;
    if (this.props.tableData.hasOwnProperty("filterFieldsApplied")) {
      let checkHeadersList = Object.keys(this.props.tableData.filterFieldsApplied);
      for (const element of checkHeadersList) {
        if (this.props.tableData.filterFieldsApplied[element].length !== 0) {
          applyFilterTable = true;
          break;
        }
      }
    }

    if (applyFilterTable) {
      docDefinition.content.push({
        table: {
          headerRows: 1,
          body: [[" ", " ", " "]]
        },
        layout: "noBorders"
      });
      let filterHeaderList = Object.keys(this.props.tableData.filterFieldsApplied);
      docDefinition.content.push({
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: "Report Filters and Options",
                fontSize: "12",
                border: [false, false, false, false],
                bold: true
              },
              { text: "", border: [false, false, false, false] }
            ],
            [
              {
                text: " ",
                fontSize: "8",
                border: [false, false, false, true],
                bold: true
              },
              { text: "", border: [false, false, false, true] }
            ]
          ]
        },
        layout: {
          hLineColor: function (i, _node) {
            return i === 2 ? "#1B57B2" : "#E0E0E0";
          }
        }
      });
      filterHeaderList.forEach((filterHeader) => {
        if (this.props.tableData.filterFieldsApplied[filterHeader].length !== 0) {
          let filterArray = [
            {
              text: filterHeader + ":",
              fontSize: "9",
              bold: true,
              border: [false, false, false, true]
            }
          ];
          let filterHeaderValues = "";
          this.props.tableData.filterFieldsApplied[filterHeader].forEach((filterHeaderValue, index) => {
            if (index === 0) filterHeaderValues = filterHeaderValue;
            else filterHeaderValues += ", " + filterHeaderValue;
          });
          filterArray.push({
            text: filterHeaderValues,
            fontSize: "9",
            border: [false, false, false, true]
          });
          docDefinition.content[docDefinition.content.length - 1].table.body.push(filterArray);
        }
      });
    }
    docDefinition.content.push({
      width: "*",
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: "Report Settings",
              fontSize: "7",
              border: [false, true, false, false],
              bold: true
            },
          ],
        ]
      },
      layout: {
        hLineColor(i, _node) {
          return i === 2 ? "#1B57B2" : "#E0E0E0";
        }
      }
    });
      docDefinition.content.push({
        width: "*",
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: "Avg Hr/Day: " + this.props.tableDataSettings.averageHoursOfUsePerDay,
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]
              },
              {
                text: "Avg Days/Week: " +this.props.tableDataSettings.averageWorkDaysPerWeek,
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]
              },
              {
                text: "Exclude Used Tyre: " + this.props.tableDataSettings.excludeUsedTires,
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]
              },
              {
                text: "Remove At:" + this.props.tableDataSettings.removeAt,
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]
              },
            ],
          ]
        },
        layout: {
          hLineColor(i, _node) {
            return i === 2 ? "#1B57B2" : "#E0E0E0";
          }
        }
      });
      docDefinition.content.push({
        width: "*",
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: "Steering Wheel Forecast:" + this.props.tableDataSettings.steeringWheelsForecastType,
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]
              },
              {
                text: "Fixed Removal Hours:" + (this.props.tableDataSettings.steeringWheelsForecastType !== "treadWearRate" ? this.props.tableDataSettings.steeringWheelsFixedRemovalHours : "N/A"),
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]

              },
              {
                text: "Driving Wheel Forecast:" + this.props.tableDataSettings.drivingWheelsForecastType,
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]
              },
              {
                text: "Fixed Removal Hours:" + (this.props.tableDataSettings.drivingWheelsForecastType !== "treadWearRate" ? this.props.tableDataSettings.drivingWheelsFixedRemovalHours : "N/A"),
                fontSize: "6",
                bold: false,
                border: [false, true, false, false]

              },
              
            ],
          ]
        },
        layout: {
          hLineColor(i, _node) {
            return i === 2 ? "#1B57B2" : "#E0E0E0";
          }
        }
      });
    
    return docDefinition.content;
  }

  tableBody = (tireNum) => {
    const body = [];
    const TableRow = [];
    let count = 0;

    const setHeader = (headerText) => {
      switch (headerText) {

        case "Total Cost":
          headerText = `Total
        Cost`;
        break;
        
        case "Total Hrs":
          headerText = `Total
        Hours`;
        break;

        case "PROJ Hrs":
          headerText = `PROJ
        Hours`;
        break;

        case "PROJ Cost":
          headerText = `PROJ
        Cost`;
        break;

        case "Hrs Left":
          headerText = `Hours
          Left`;
        break;

        case "PrjRMVL Date":
          headerText = `PrjRMVL
          Date`;
        break;

        default:
        break;
      }
      return headerText;
      
    }
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    let percent = 100;
    let remCount = count;
    this.props.tableData.headCells.forEach((headCell, index) => {
      body.splice(0, body.length);
      if (this.props.tableData.selectedColShow[headCell.value] === true) {
        
        let headCellName = commonUtils.pdfHeaderNameShorter( headCell.key);
        let headerText = setHeader(headCellName); 

        TableRow.push(
          this.thl(headerText, "header", {
            border: [false, false, false, true],
            fontSize: 7,
            fillColor: "white",
            color: "#2E2E2E",
            bold: true
          }, -1)
        );
        if (count > 13) {
          if(headCell.label === "Position"  
          || headCell.label === "Total Cost" || headCell.label === "Cost/ Hr Projected" || headCell.label === "Cost/ Hr Projected"
          || headCell.label === "HoursPerTreadDepth"
          ) {
            this.tableColumnWidths.push("3%");
            percent = percent - 3;
            remCount = remCount - 1;
          } 
          else if (headCell.label === "Brand #" || headCell.label === "Serial #" || headCell.label === "Type"){
            this.tableColumnWidths.push("10%");
            percent = percent - 10;
            remCount = remCount - 1;
          }
          else if (headCell.label === "Manufacturer"  || headCell.label === "Tread (Out,In)/Org (mm)" || headCell.label === `Tread (Out,In)/Org(32")`){
            this.tableColumnWidths.push("7%");
            percent = percent - 7;
            remCount = remCount - 1;
          }
          else if(headCell.label === "Size"
          || headCell.label === "Projected Removal Date" || headCell.label === "Projected Hrs" || headCell.label === "Total Hours") {
            this.tableColumnWidths.push("6%");
            percent = percent - 6;
            remCount = remCount - 1;
          }
          else if (headCell.label === "%Worn") {
            this.tableColumnWidths.push("4%");
            percent = percent - 4;
            remCount = remCount - 1;
          }
           else {
            this.tableColumnWidths.push(percent/remCount +"%");
          }
        } else {
          if (headCell.label === "Manufacturer" || headCell.label === "Brand #" || headCell.label === "Serial #" || headCell.label === "Type"){
            this.tableColumnWidths.push("11.5%");
            percent = percent - 11.5;
            remCount = remCount - 1;
          } else {
            this.tableColumnWidths.push(percent/remCount +"%");
          }
        }

        if (this.tableColumnWidths.length > count) this.tableColumnWidths.length = count;
      }
    });

    body.splice(0, body.length);
    body.push();
    body.push(TableRow);
    this.props.tableData.rowsData[tireNum].forEach((item, index) => {
      const tableRow = [];
      let last = this.props.tableData.rowsData.length - 1 > index ? 0 : 1;
      if (last === 1 && !this.props.tableData.avgValues) {
        last = 0;
      }
      // eslint-disable-next-line array-callback-return
      this.props.tableData.headCells.forEach((headCell, indexHeadCell) => {
        const key = headCell.value;
        if (key === "ProjectedRemovalDate" && (item[key] === "NaN/NaN/NaN" || !item[key] || item[key] === "01/01/0001")) {
          item[key] = "";
        } else {
          if (key === "ProjectedRemovalDateByMiles" && (item[key] === "NaN/NaN/NaN" || !item[key] || item[key] === "01/01/0001")) {
            item[key] = "";
          }
        }
        if (key !== "Key" && this.props.tableData.selectedColShow[key] === true) {
          let wornText = "";
          if (key === "PercentageWorn") {
            if (item[key] <= 25) {
              wornText = "low";
            } else if (item[key] > 25 && item[key] <= 50) {
              wornText = "avg";
            } else if (item[key] > 50 && item[key] <= 75) {
              wornText = "high";
            } else {
              wornText = "vhigh";
            }
          } else {
            wornText = "data";
          }

          const percentageAdder = (item) => {
            if (key === "PercentageWorn" || key === 'PercentRecomPressure') {
              return item + '%';
            } else {
              return item;
            }
          }

          tableRow.push(
            this.tdl(percentageAdder(item[key]), wornText, {
              fontWeight: 400,
              color: "#333333",
              fontSize: 6,
              border: last === 1 ? [false, false, false, false] : [false, false, false, true]
            }, indexHeadCell)
          );
        }
      });

      body.push(tableRow);
    });
    // });

    return body;
  };

  /** Doc definition */
  async createDocumentDefinition() {
    const baseDocDefinition = {
      pageSize: "A4",
      pageMargins: [20, 65, 20, 55],
      pageOrientation: "landscape",
      content: []
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.header = await this.addPdfMakeHeader();
    docDefinition.footer = this.addPdfMakeFooter();
    docDefinition.content.push(await this.generateRemovedTirePDF());
    const defaultFont = {defaultStyle: {
      font: 'Barlow'
    }}
    docDefinition.defaultStyle = defaultFont;
    return docDefinition;
  }

  /** header body and footer */
  async addPdfMakeHeader() {

    return (_currentPage, _pageCount) => {
      return {
        margin: [20, 20, 20, 0],
        stack: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    
                    color: "white",
                    fillColor: "#1250B0",
                    alignment: "right",
                    columns: [
                      {
                        svg: commonUtils.EmTrackSVGLogo,
                        fit: [50, 80],
                        width: "5%",
                        margin: [0, 2, 0, 2],
                        alignment: "left"
                      },
                      {
                        text: " " + commonUtils.pdfReportName(this.props.tableData.reportName),
                        bold: true,
                        alignment: "center",
                        fontSize: 14,
                        margin: [0, 10, 0, 2]
                      },
                      {
                        svg: commonUtils.GoodyearSVGLogo,
                        alignment: "right",
                        width: "10%",
                        fit: [90, 150]
                      },
                    ]
                  }
                ]
              ]
            }
          }
        ]
      };
    };
  }

  addPdfMakeFooter = () => {
    let footerText = "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
    return (currentPage, pageCount) => {
      // you can apply any logic and return any valid pdfmake element
      return {
        margin: [20, 0, 20, 0],
        border: [false, false, false, false],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                color: "white",
                fillColor: "#1250B0",
                alignment: "left",
                columns: [
                  {
                    text: footerText,
                    alignment: "left",
                    width: "40%",
                    margin: [0, 13, 0, 0],
                    bold: true
                  },
                  {
                    text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                    alignment: "right",
                    margin: [0, 13, 0, 0],
                    fontWeight: 400
                  }
                ]
              }
            ]
          ]
        }
      };
    };
  };


  async makeTableData() {
    // -- Create a base document template for the reports.
    const docDefinition = await this.createDocumentDefinition();
    return pdfMake.createPdf(docDefinition);
  }
  render() {
    return <div></div>;
  }
}

export default TireForecastPDF;
