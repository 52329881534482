import React, { useEffect, useState, useContext } from "react";
import "./DailyJobSummary.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import { Card, CardContent, TextField, InputLabel, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import DailyJobSummaryDataSetter from "../../../../../services/DailyJobSummaryUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import DailyJobSummaryTable from "../table/DailyJobSummaryTable";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import PrintEmailExportSetButtons from "../../../../../../../common-components/filterPrintEmailExport-SetButtons/PrintEmailExportSetButton"
import { set } from "date-fns";

const DailyJobSummary = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const commonUtils = new CommonUtils();
  const dailyJobSummaryDataSetter = new DailyJobSummaryDataSetter();

  const [dailyJobSummaryTableData, setDailyJobSummaryTableData] = useState();
  const [showColumnsArray, setShowColumnsArray] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [printData, setPrintData] = useState(dailyJobSummaryTableData);
  const [noData, setNoData] = useState(false);
  const [averageHoursRadial, setAverageHoursRadial] = useState(3500);
  const [averageHoursBiasPly, setAverageHoursBiasPly] = useState(2200);
  const [averageWornRadial, setAverageWornRadial] = useState(85);
  const [averageWornBiasPly, setAverageWornBiasPly] = useState(85);
  const [averageHoursRadialValue, setAverageHoursRadialValue] = useState("");
  const [averageHoursBiasPlyValue, setAverageHoursBiasPlyValue] = useState("");
  const [averageWornRadialValue, setAverageWornRadialValue] = useState("");
  const [averageWornBiasPlyValue, setAverageWornBiasPlyValue] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [excludeUsedTires, setExcludeUsedTires] = useState(false);

  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const filterApplied = false;
  const filterOptionMainCategory = "AllTime";
  const dateRangeFilter = {
    fromDate: "",
    toDate: ""
  }
  let today = new Date();
  let day = today.getDate().toString().length === 1 ? "0" + today.getDate().toString() : today.getDate().toString();
  let month = (today.getMonth() + 1).toString().length === 1 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1).toString();
  let year = today.getFullYear();
  let len = month.length;
  let len1 = day.length;
  if (len === 1) month = "0" + month;

  if (len1 === 1) day = "0" + day;
  let date = year + "-" + month + "-" + day;
  const [reportEndDate, setReportEndDate] = useState(date);
  const [reportEndDateValue, setReportEndDateValue] = useState("");

  const [tableDataSettings, setTableDataSettings] = useState({
    excludeUsedTires: false,
    averageHoursRadial: 3500,
    averageHoursBiasPly: 2200,
    averageWornRadial: 85,
    averageWornBiasPly: 85,
    reportEndDateValue: date,
  });

  useEffect(() => {
    DailyJobSummaryResponseData();
  }, [globalAccountState]);

  const globalizationMapping = new Globalization();

  const DailyJobSummaryResponseData = () => {
    // FORMATTING THE GOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      reportOption: "",
      reportName: "Daily_Job_Summary",
      dateRangeType: filterOptionMainCategory,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      targetHoursRadial: averageHoursRadial,
      targetHoursBias: averageWornBiasPly,
      targetWornRadial: averageWornRadial,
      targetWornBias: averageWornBiasPly,
      reportEndDate: reportEndDate,
      usedTires: excludeUsedTires.toString()
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let tiresInServiceTableInfo = dailyJobSummaryDataSetter.setData(data, reportEndDate, averageHoursRadial, averageWornRadial, averageHoursBiasPly, averageWornBiasPly);
      tiresInServiceTableInfo.length === 0
      ? setNoData(true)
      : setNoData(false);
      setDailyJobSummaryTableData(tiresInServiceTableInfo);
      let showColArr = [];
      let disColOptArr = [];
      let disColArr = [];
      let rowsDataArr = [];
      tiresInServiceTableInfo.forEach((tableData, index) => {
        showColArr.push(tableData.selectedColShow);
        disColOptArr.push(tableData.headCells);
        disColArr.push(tableData.headCells);
        rowsDataArr.push(tableData.rowsData);
      });
      setShowColumnsArray(showColArr);

      let allTablesData = JSON.parse(JSON.stringify(tiresInServiceTableInfo));
      const monthMapper = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
      };
      let monthNumber = parseInt(reportEndDate.split("-")[1]);
      let lastMonthNUmber = monthNumber - 1;

      if (monthNumber === 1) lastMonthNUmber = 12;

      let month = monthMapper[monthNumber];
      let lastMonth = monthMapper[lastMonthNUmber];
      let year = reportEndDate.split("-")[0];
      allTablesData.forEach((tbl, index) => {
        switch (index) {
          case 0:
            tbl.headCells[0].label = "Radial Tires Scrapped & Reporting Periods: " + year;
            tbl.rowsData[0].ReportingPeriods += " " + year;
            tbl.rowsData[1].ReportingPeriods += " " + lastMonth;
            tbl.rowsData[2].ReportingPeriods += " " + month;
            tbl.rowsData[3].ReportingPeriods += " " + reportEndDate;
            break;
          case 1:
            tbl.headCells[0].label = "Bias Tires Scrapped & Reporting Periods: " + year;
            tbl.rowsData[0].ReportingPeriods += " " + year;
            tbl.rowsData[1].ReportingPeriods += " " + lastMonth;
            tbl.rowsData[2].ReportingPeriods += " " + month;
            tbl.rowsData[3].ReportingPeriods += " " + reportEndDate;
            break;
          case 2:
            tbl.headCells[0].label = "Tire Installation Summary & Reporting Periods: " + year;
            break;
          case 3:
            tbl.headCells[0].label = "Tire repair Summary & Reporting Periods: " + year;
            break;
          default:
        }
      });

      setPrintData({
        reportType: "Tires in Services : ",
        reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.DailyJobSummary,
        rowsDataArray: rowsDataArr,
        headCellsArray: disColArr,
        //avgValues: tiresInServiceTableInfo.avgValues,
        allTablesData: allTablesData,
        selectedColShowArray: showColArr,
        filterApplied: filterApplied
      });

      setShowSpinner(false);
    });
    // setAverageHoursRadialValue(averageHoursRadial);
    // setAverageHoursBiasPlyValue(averageHoursBiasPly);
    // setAverageWornRadialValue(averageWornRadial);
    // setAverageWornBiasPlyValue(averageWornBiasPly);
    // setReportEndDateValue(reportEndDate);

    setAverageHoursRadial(averageHoursRadial);
    setAverageHoursBiasPly(averageHoursBiasPly);
    setAverageWornRadial(averageWornRadial);
    setAverageWornBiasPly(averageWornBiasPly);
    setReportEndDate(reportEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };



  const onClickExport = (type) => {
    //
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const handleUpdateAverageHoursRadial = (value) => {
    setAverageHoursRadial(value);
  };
  const handleUpdateAverageHoursBiasPly = (value) => {
    setAverageHoursBiasPly(value);
  };
  const handleUpdateAverageWornRadial = (value) => {
    setAverageWornRadial(value);
  };
  const handleUpdateAverageWornBiasPly = (value) => {
    setAverageWornBiasPly(value);
  };
  const handleUpdateReportEndDate = (event) => {
    setReportEndDate(event.target.value);
  };
  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
  };

  const updateReport = () => {
    setTableDataSettings({
      ...tableDataSettings,
      excludeUsedTires: excludeUsedTires,
      averageHoursRadial: averageHoursRadial,
      averageHoursBiasPly: averageHoursBiasPly,
      averageWornRadial: averageWornRadial,
      averageWornBiasPly: averageWornBiasPly,
      reportEndDateValue: reportEndDate
    })
    DailyJobSummaryResponseData();
  };

  return (
    <div className="container">
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="tire-installation-container">
          <Card className="tire-installation-card">
            <CardContent>
              <Grid container>
                <Grid item xs={3}>
                  <InputLabel className="filter-table-label">
                    <span className="table-toolbar-label-text">{globalizationMapping.DailyJobSummaryWORDS.AvgHoursofRadialTires}</span>
                  </InputLabel>
                  <TextField variant="outlined" value={averageHoursRadial} onChange={ (e) => (handleUpdateAverageHoursRadial(commonUtils.minmaxInt(e.target.value, 0, 999999)))} type="number" inputProps={{ maxLength: 50 }}/>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel className="filter-table-label">
                    <span className="table-toolbar-label-text">{globalizationMapping.DailyJobSummaryWORDS.AvgWornofRadialTires}</span>
                  </InputLabel>
                  <TextField variant="outlined" value={averageWornRadial} onChange={(e) =>(handleUpdateAverageWornRadial(commonUtils.minmaxInt(e.target.value, 0, 999999)))} type="number" inputProps={{ maxLength: 50 }}/>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel className="filter-table-label">
                    <span className="table-toolbar-label-text">{globalizationMapping.DailyJobSummaryWORDS.ReportEndDate}</span>
                  </InputLabel>
                  <TextField
                    id="date"
                    type="date"
                    // className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={handleUpdateReportEndDate}
                    value={reportEndDate}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Grid container style={{justifyContent:'end'}}>
                    <PrintEmailExportSetButtons
                      onClickEmail={onClickEmail}
                      onClickPrint={onClickPrint}
                      onClickExport={onClickExport}
                      toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)}
                      ref={ref}
                      noData={noData}
                      isComponentVisible={isComponentVisible}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <div className="dailyjob-second-row-dropdown">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.DailyJobSummaryWORDS.AvgHoursofBiasTires}</span>
                    </InputLabel>
                    <TextField variant="outlined" value={averageHoursBiasPly} onChange={(e) => (handleUpdateAverageHoursBiasPly(commonUtils.minmaxInt(e.target.value, 0, 999999)))} type="number" inputProps={{ maxLength: 50 }}/>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="dailyjob-second-row-dropdown">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.DailyJobSummaryWORDS.AvgWornofBiasTires}</span>
                    </InputLabel>
                    <TextField variant="outlined" value={averageWornBiasPly} onChange={(e) => (handleUpdateAverageWornBiasPly(commonUtils.minmaxInt(e.target.value, 0, 999999)))} type="number" inputProps={{ maxLength: 50 }}/>
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <div className="dailyjob-exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
                {/* Update Button */}
                <Grid item xs={3}>
                  <div className="button-container">
                    <button className="update-button" variant="contained" color="primary" onClick={updateReport}>
                      {globalizationMapping.INSPECT.UPDATE}
                    </button>
                  </div>
                </Grid>
              </Grid>
              {dailyJobSummaryTableData?.map((item, index) => {
                return (
                  <div key={item}>
                    <div className="table-toolbar table-toolbar-dailyJob">
                      <div className="table-filters"></div>
                    </div>

                    <DailyJobSummaryTable
                      key={index}
                      tableData={item}
                      showColumns={showColumnsArray[index]}
                      filterApplied={filterApplied}
                      tableNumber={index}
                      reportEndDate={reportEndDate}
                      avgHrsRadial={averageHoursRadial}
                      avgHrsBiasPly={averageHoursBiasPly}
                      avgWornRadial={averageWornRadial}
                      avgWornBiasPly={averageWornBiasPly}
                    />
                  </div>
                );
              })}
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} tableDataSettings={tableDataSettings}/>
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} tableDataSettings={tableDataSettings}/>
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} dailyJobSummary={true} />
    </div>
  );
};

export default DailyJobSummary;
