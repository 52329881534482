import React, { useEffect, useState, useContext } from "react";
import "./ScrapAnalysis.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import ScrapTiresNavigation from "../../../scrap-tires-navigation/components/ScrapTiresNavigation";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import ScrapTiresAnalysisDataSetter from "../../../../../services/ScrapAnalysisUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import ScrapAnalysisTable from "../table/ScrapAnalysisTable";
import ScrapAnalysisSizeTable from "../table/ScrapAnalysisSizeTable";
import ScrapAnalysisTypeTable from "../table/ScrapAnalysisTypeTable";
import ScrapAnalysisManufacturerTable from "../table/ScrapAnalysisManufacturerTable";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import ScrapAnalysisGraph from "../graph/ScrapAnalysisGraph";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ScrapAnalysisFilter from "../filter/ScrapAnalysisFilter.js";


const ScrapAnalysis = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const globalizationMapping = new Globalization();

  const tiresService = new TiresService();
  const scrapTiresAnalysisDataSetter = new ScrapTiresAnalysisDataSetter();
  const commonUtils = new CommonUtils();
  const [scrapAnalysisTableData, setScrapAnalysisTableData] = useState();
  const [originalScrapAnalysisTableData, setOriginalScrapAnalysisTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [tableDataSettings, setTableDataSettings] = useState({
    excludeUsedTires: false,
    tireScrapType: "AllTire",
  });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [toggleHourMiles, setToggleHourMiles] = useState("");
  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfo")).userSetting.userMeasurementSystemValue.toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Removal Reason", value: "RemovalReason" },
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Type", value: "TypeName" },
      { name: "Size", value: "SizeName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      RemovalReason: [],
      ManufacturerName: [],
      TypeName: [],
      SizeName: [],
      CompoundName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const [printData, setPrintData] = useState(scrapAnalysisTableData);
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const valueLostBy = "Scrap_Analysis";

  useEffect(() => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    ScrapAnalysisResponseData(tireScrapType, valueLostBy, excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const ScrapAnalysisResponseData = (tireScrapTypeValue, valueLostByValue, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let inServiceDateRangeType = "AllTime";
    let outServiceDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (outServiceDateRange.fromDate !== "" && outServiceDateRange.toDate !== "") outServiceDateRangeType = "DateRange";

    let requestObject = {
      reportOption: "",
      reportName: valueLostByValue,
      dateRangeType: dateRangeType,
      InServiceDateRangeType: inServiceDateRangeType,
      OutServiceDateRangeType: outServiceDateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      TireScrapType: tireScrapTypeValue,
      usedTires: excludeUsedTiresValue.toString()
    };

    setShowSpinner(true);
    let filterOptions;
    tiresService.getTiresReport(requestObject).then((data) => {
      let scrapAnalysisTableInfo;
      let filterOptionsInfo;
      scrapAnalysisTableInfo = scrapTiresAnalysisDataSetter.setData(data);
      filterOptions = {
        categories: [
          { name: globalizationMapping.REPORTTABLE.MANUFACTURER, value: "ManufacturerName"},
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          { name: globalizationMapping.REPORTTABLE.REMOVALREASON, value: "RemovalReason" },
          { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName"}
        ],
        categoryData: {
          RemovalReason: [],
          ManufacturerName: [],
          TypeName: [],
          SizeName: [],
          CompoundName: [],
          outServiceDateToDate: '',
          outServiceDateFromDate: '',
          inServiceDateFromDate: '',
          inServiceDateToDate: '',
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      filterOptionsInfo = scrapTiresAnalysisDataSetter.setFilterOptionsData(filterOptions, scrapAnalysisTableInfo.rowsData);

      scrapAnalysisTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setScrapAnalysisTableData(scrapAnalysisTableInfo);
      setOriginalScrapAnalysisTableData(scrapAnalysisTableInfo);
      setTableBeforeFilter(scrapAnalysisTableInfo);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(scrapAnalysisTableInfo.headCells);
      setDisplayColumns(scrapAnalysisTableInfo.headCells);
      setShowColumns(scrapAnalysisTableInfo.selectedColShow);
      setPrintData({
        reportType: "Scrap Tires : ",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
        rowsData: scrapAnalysisTableInfo.rowsData,
        headCells: scrapAnalysisTableInfo.headCells,
        avgValues: scrapAnalysisTableInfo.avgValues,
        selectedColShow: scrapAnalysisTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      setShowSpinner(false);

      if (filterAppliedValue) {
        let dataSetter = scrapTiresAnalysisDataSetter;

        setFilterApplied(true);
        let originalTableDataStore = scrapAnalysisTableInfo.rowsData;
        let filteredData = dataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setScrapAnalysisTableData({
          ...scrapAnalysisTableInfo,
          rowsData: filteredData,
          avgValues: dataSetter.avgValues(filteredData)
        });
        setOriginalScrapAnalysisTableData({
          ...scrapAnalysisTableInfo,
          rowsData: filteredData,
          avgValues: dataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: dataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        (((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };


  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };
  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let originalTableDataStore, filteredData;
    if (showColumns && showColumns.AverageHours) {
      originalTableDataStore = originalScrapAnalysisTableData?.rowsData.map(({ AverageMiles, AverageCostPerMile, ...rest }) => {
        return rest;
      });
    } else {
      originalTableDataStore = originalScrapAnalysisTableData?.rowsData.map(({ AverageHours, AverageCostPerHour, ...rest }) => {
        return rest;
      });
    }
    let dataSetter = scrapTiresAnalysisDataSetter;

    filteredData = dataSetter.search(originalTableDataStore, searchText);
    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalScrapAnalysisTableData?.headCells,
      avgValues: dataSetter.avgValues(filteredData),
      selectedColShow: originalScrapAnalysisTableData?.selectedColShow
    };
    setScrapAnalysisTableData(updatedTableData);
    setPrintData({
      ...printData,
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
      // reportName: "Value Lost by Removal Reason",
      rowsData: filteredData,
      headCells: originalScrapAnalysisTableData?.headCells,
      selectedColShow: originalScrapAnalysisTableData?.selectedColShow,
      avgValues: dataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let dataSetter = scrapTiresAnalysisDataSetter;

    let currentHeadCells = originalScrapAnalysisTableData?.headCells;
    let showColumnsUpdated = dataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      RemovalReason: [],
      CompoundName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    });
    setFilterApplied(false);
    setScrapAnalysisTableData(tableBeforeFilter);
    setOriginalScrapAnalysisTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };
  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    
    let toSetCurrentFilter = 
    { ...appliedFilter,
      outServiceDateToDate: outServiceDateRange.toDate,
      outServiceDateFromDate:  outServiceDateRange.fromDate,
      inServiceDateFromDate: inServiceDateRange.fromDate,
      inServiceDateToDate: inServiceDateRange.toDate,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

  setCurrentFilter(toSetCurrentFilter);
  if (appliedFilter === filterOptions.categoryData) {
    setFilterApplied(false);
  } else {setFilterApplied(true)}

    setToggleHourMiles("Hours");
    actionChanged("Hours");
    setFilterFieldsApplied(appliedFilter);
    ScrapAnalysisResponseData(tireScrapType, valueLostBy, excludeUsedTires, true, appliedFilter);
    setFilterApplied(true);
    let originalTableDataStore = originalScrapAnalysisTableData?.rowsData;
    let filteredData = scrapTiresAnalysisDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setScrapAnalysisTableData({
      ...scrapAnalysisTableData,
      rowsData: filteredData,
      avgValues: scrapTiresAnalysisDataSetter.avgValues(filteredData)
    });

    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: scrapTiresAnalysisDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);


  };

  const handleUpdateTireScrapType = (event) => {
    setTableDataSettings({
      ...tableDataSettings,
      tireScrapType: event.target.value
    })
    setTireScrapType(event.target.value);
    setToggleHourMiles("Hours");
    actionChanged("Hours");
    ScrapAnalysisResponseData(event.target.value, valueLostBy, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setTableDataSettings({
      ...tableDataSettings,
      excludeUsedTires: event.target.checked
    })
    setExcludeUsedTires(event.target.checked);
    setToggleHourMiles("Hours");
    actionChanged("Hours");
    ScrapAnalysisResponseData(tireScrapType, valueLostBy, event.target.checked, filterApplied, filterFieldsApplied);
  };
  function actionChanged(eValue) {
    setToggleHourMiles(eValue);
    if (eValue === "Miles") {
      handleMilesClick();
    } else if (eValue === "Hours") {
      handleHoursClick();
    } else {
      return;
    }
  }

  const handleMilesClick = () => {
    let arrOfCol = ["AverageHours", "AverageCostPerHour", "AverageWearRate"];
    let scrapInfo = scrapAnalysisTableData;

    scrapInfo.headCells.forEach((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      scrapInfo.selectedColShow[item.value] = flag;
    });

    setScrapAnalysisTableData(scrapInfo);
    scrapInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = scrapInfo.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColumns(updatedHeadCells);
    setDisplayColOptions(updatedHeadCells);
    setShowColumns(scrapInfo.selectedColShow);
    setPrintData({
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
      rowsData: scrapInfo.rowsData,
      headCells: scrapInfo.headCells,
      avgValues: scrapInfo.avgValues,
      selectedColShow: scrapInfo.selectedColShow
    });
  };

  const handleHoursClick = () => {
    let arrOfCol = ["AverageMiles", "AverageCostPerMile", "AverageWearRatePerMile"];

    let scrapInfo = scrapAnalysisTableData;

    scrapInfo.headCells.forEach((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      scrapInfo.selectedColShow[item.value] = flag;
    });

    setScrapAnalysisTableData(scrapInfo);
    scrapInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = scrapInfo.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColumns(updatedHeadCells);
    setDisplayColOptions(updatedHeadCells);
    setShowColumns(scrapInfo.selectedColShow);
    setPrintData({
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
      rowsData: scrapInfo.rowsData,
      headCells: scrapInfo.headCells,
      avgValues: scrapInfo.avgValues,
      selectedColShow: scrapInfo.selectedColShow
    });
  };
  return (
    <div className="container">
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="scrap-analysis-container">
          <Card className="scrap-analysis-card">
            <CardContent>
              <CardContent className="graph-card">
                {valueLostBy === "Scrap_Analysis" && <ScrapAnalysisGraph scrapAnalysisGraphData={scrapAnalysisTableData?.rowsData} />}
              </CardContent>
              <div className="table-toolbar">
                <div className="table-filters-scrap-analysis">
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="search-table-container">
                        <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                          <InputLabel className="filter-table-label">
                            <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                          </InputLabel>
                          <TextField
                            className="search-table-bar"
                            id="input-with-icon-textfield"
                            variant="outlined"
                            placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                            onChange={searchTableData}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                                </InputAdornment>
                              )
                            }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="display-columns-container" id="dashboard-display-column">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                        </InputLabel>
                        <MultiSelect
                          options={displayColOptions}
                          value={displayColumns}
                          onChange={changeDisplayColumns}
                          overrideStrings={{
                            selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                            allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                            selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                            search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="display-columns-container" id="dashboard-display-column">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                        </InputLabel>
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="manage-vehicle-action-dropdown"
                          variant="outlined"
                          value={toggleHourMiles}
                          onChange={(e) => actionChanged(e.target.value)}
                        >
                          <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                          <option value={"Miles"}>{toggleMile}</option>
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="tire-scrap-type">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.GRAPH.TireScrapType}</span>
                        </InputLabel>
                        <Select
                          native 
                          IconComponent={ExpandMoreStyledIcon}
                          className="select-drop-down"
                          value={tireScrapType}
                          name="tire scrap type"
                          variant="outlined" 
                          onChange={handleUpdateTireScrapType}>
                          <option value="AllTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption1} </option>
                          <option value="Repair">{globalizationMapping.REPORTCOMMONWORDS.TypeOption2} </option>
                          <option value="Retread">{globalizationMapping.REPORTCOMMONWORDS.TypeOption3} </option>
                          <option value="NewTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption4} </option>
                        </Select>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        className="
                          exclude-used-tires"
                      >
                        <FormControlLabel
                          control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                          label={globalizationMapping.GRAPH.ExcludeUsedTires}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>

              <div className="scrap-analysis-table">
                {valueLostBy === "Scrap_Analysis" && <ScrapAnalysisTable tableData={scrapAnalysisTableData} showColumns={showColumns} filterApplied={filterApplied} />}
                {valueLostBy === "Scrap_Analysis_ByType" && <ScrapAnalysisTypeTable tableData={scrapAnalysisTableData} showColumns={showColumns} filterApplied={filterApplied} />}
                {valueLostBy === "Scrap_Analysis_BySize" && <ScrapAnalysisSizeTable tableData={scrapAnalysisTableData} showColumns={showColumns} filterApplied={filterApplied} />}
                {valueLostBy === "Scrap_Analysis_ByManufacturer" && (
                  <ScrapAnalysisManufacturerTable tableData={scrapAnalysisTableData} showColumns={showColumns} filterApplied={filterApplied} />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} tableDataSettings={tableDataSettings}/>
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} tableDataSettings={tableDataSettings}/>
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />

      <ScrapAnalysisFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ScrapAnalysis;
