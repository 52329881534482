import React, { useEffect, useState, useContext } from "react";
import "./BySize.css";
import { Card, CardContent, InputLabel, TextField, InputAdornment, FormControlLabel, Checkbox, Grid, Select } from "@material-ui/core";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import BySizeDataSetter from "../../../../../services/BySizeUtils";
import BySizeGraph from "../graph/BySizeGraph";
import BySizeTable from "../table/BySizeTable";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import BySizeFilter from '../filter/BySizeFilter'

const BySize = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const bySizeDataSetter = new BySizeDataSetter();
  const commonUtils = new CommonUtils();
  const [bySizeTableData, setBySizeTableData] = useState();
  const [originalBySizeTableData, setOriginalBySizeTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(bySizeTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [tableDataSettings, setTableDataSettings] = useState({
    excludeUsedTires: false,
  });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  const BySizeResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      locationIds: locationIds,
      reportName: "Tire_In_Service_By_Tire_Size",
      reportOption: "",
      usedTires: excludeUsedTiresValue.toString()
    };
    
    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let bySizeTableInfo = bySizeDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "ManufacturerName"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }

        ],
        categoryData: {
          ManufacturerName: [],
          SizeName: [],
          TypeName: [],
          CompoundName: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = bySizeDataSetter.setFilterOptionsData(filterOptions, bySizeTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      setBySizeTableData(bySizeTableInfo);
      setOriginalBySizeTableData(bySizeTableInfo);
      setTableBeforeFilter(bySizeTableInfo);
      bySizeTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setDisplayColOptions(bySizeTableInfo.headCells);
      setDisplayColumns(bySizeTableInfo.headCells);
      setShowColumns(bySizeTableInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.BySize,
        rowsData: bySizeTableInfo.rowsData,
        headCells: bySizeTableInfo.headCells,
        avgValues: bySizeTableInfo.avgValues,
        selectedColShow: bySizeTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = bySizeTableInfo.rowsData;
        let filteredData = bySizeDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setBySizeTableData({
          ...bySizeTableInfo,
          rowsData: filteredData.rowData,
          avgValues: bySizeDataSetter.avgValues(filteredData.rowData),
          graphData: filteredData.graphData
        });
        setOriginalBySizeTableData({
          ...bySizeTableInfo,
          rowsData: filteredData.rowData,
          avgValues: bySizeDataSetter.avgValues(filteredData.rowData),
          graphData: filteredData.graphData
        });
        setPrintData({
          ...printData,
          rowsData: filteredData.rowData,
          avgValues: bySizeDataSetter.avgValues(filteredData.rowData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true,
          graphData: filteredData.graphData
        });
        filteredData.rowData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    BySizeResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalBySizeTableData?.headCells;
    let showColumnsUpdated = bySizeDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  const actionChanged = (value) => {
    let arrOfCol = [];
    if (value === "miles") arrOfCol = ["AverageHours", "WearRate"];
    else arrOfCol = ["AverageMiles", "WearRatePerMile"];

    const displayColSection = bySizeTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });
    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalBySizeTableData?.headCells;
    let showColumnsUpdated = bySizeDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);
    setBySizeTableData({
      ...bySizeTableData,
      selectedColShow: showColumnsUpdated
    });

    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    // searchText === "" ? setFilterApplied(false) : setFilterApplied(true)
    let tableDataStore, filteredData;
    tableDataStore = originalBySizeTableData?.rowsData;
    filteredData = bySizeDataSetter.search(tableDataStore, searchText);
    setBySizeTableData({
      ...bySizeTableData,
      rowsData: filteredData,
      avgValues: bySizeDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.BySize,

      rowsData: filteredData,

      avgValues: bySizeDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setSearchValue("");
    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalBySizeTableData?.rowsData;
    let filteredData = bySizeDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setBySizeTableData({
      ...bySizeTableData,
      rowsData: filteredData.rowData,
      avgValues: bySizeDataSetter.avgValues(filteredData.rowData),
      graphData: filteredData.graphData
    });
    setPrintData({
      ...printData,
      rowsData: filteredData.rowData,
      avgValues: bySizeDataSetter.avgValues(filteredData.rowData),
      graphData: filteredData.graphData
    });
    filteredData.rowData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
    BySizeResponseData(excludeUsedTires, true, appliedFilter);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });
    setFilterApplied(false);
    setBySizeTableData(tableBeforeFilter);
    setOriginalBySizeTableData(tableBeforeFilter);
    let temp = JSON.parse(JSON.stringify(printData));
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      selectedColShow: temp.selectedColShow,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApllied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApllied).forEach((index) => {
      tempFilterFieldsApllied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApllied);
    setOnlyFilterApplied(false);
  };


  const onClickFilter = () => {
    setShowFilter(true);
  };

  const handleExcludeUsedTires = (event) => {
    setTableDataSettings({excludeUsedTires: event.target.checked});
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
    BySizeResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div>
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-size-container">
          <Card className="by-size-card">
            <CardContent>
              <BySizeGraph bySizeGraphData={bySizeTableData} showColumns={showColumns} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  {/* <Grid item xs={3}> */}
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value={"hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"miles"}> {toggleMile}</option>
                    </Select>
                  </div>
                  {/* </Grid> */}
                </div>

                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <BySizeTable tableData={bySizeTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tableDataSettings={tableDataSettings}/>
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tableDataSettings={tableDataSettings}/>
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <BySizeFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default BySize;
