import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, InputLabel, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import ScrapTiresNavigation from "../../../../scrap-tires/scrap-tires-navigation/components/ScrapTiresNavigation";
import ScrapHoursByTireTypeDataSetter from "../../../../../services/ScrapHoursByTireTypeUtils";
import ScrapHoursByTireTypeGraph from "../graph/ScrapHoursByTireTypeGraph";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ScrapHoursByTireTypeFilter from "../filter/ScrapHoursByTireTypeFilter.js"

const ScrapHoursByTireType = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const globalizationMapping = new Globalization();
  const tiresService = new TiresService();
  const scrapHoursByTireTypeDataSetter = new ScrapHoursByTireTypeDataSetter();
  const commonUtils = new CommonUtils();

  const [showSpinner, setShowSpinner] = useState(true);
  const [scrapHoursByTireTypeGraphData, setScrapHoursByTireTypeGraphData] = useState();
  const [printData, setPrintData] = useState();
  const [originalScrapHoursByTireTypeTableData, setOriginalScrapHoursByTireTypeTableData] = useState();
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [tableDataSettings, setTableDataSettings] = useState({excludeUsedTires: false});
  const [showFilter, setShowFilter] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [filterOptions, setFilterOptions] = useState({
    categories: [{ name: "Size", value: "Size" }],
    categoryData: {
      Size: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const ScrapHoursByTireTypeResponseData = (excludeUsedTiresValue, appliedFilter) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let inServiceDateRangeType = "AllTime";
    let outServiceDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (outServiceDateRange.fromDate !== "" && outServiceDateRange.toDate !== "") outServiceDateRangeType = "DateRange";
    let tempFilterOptions = {
      categories: [{ name: globalizationMapping.REPORTTABLE.SIZE, value: "Size" }],
      categoryData: {
        Size: [],
        outServiceDateToDate: '',
        outServiceDateFromDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
      }
    };
    let requestObject = {
      accountIds: accountIds,
      dateRangeType: "AllTime",
      fromDate: '',
      toDate: '',
      graphType: "Bar",
      locationIds: locationIds,
      reportName: "GetScrapHoursTireType",
      usedTires: excludeUsedTiresValue.toString(),
      InServiceDateRangeType: inServiceDateRangeType,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      OutServiceDateRangeType: outServiceDateRangeType,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate
    };

    setShowSpinner(true);
    tiresService.getTiresGraph(requestObject).then((data) => {
      let scrapHoursByTireTypeGraphInfo = scrapHoursByTireTypeDataSetter.setGraphData(data);
      let sizes = scrapHoursByTireTypeDataSetter.setFilterOptionsData(tempFilterOptions, scrapHoursByTireTypeGraphInfo);
      setFilterOptions(sizes);
      setScrapHoursByTireTypeGraphData(scrapHoursByTireTypeGraphInfo);
      setOriginalScrapHoursByTireTypeTableData(scrapHoursByTireTypeGraphInfo);
      // static preset printData to allow only graph printing
      setPrintData({
        reportType: "Scrap Tires: ",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireType,
        rowsData: [],
        headCells: [],
        avgValues: [],
        selectedColShow: [],
        filterApplied: false
      });
      setDisplayColOptions(scrapHoursByTireTypeDataSetter.setDisplayColumns(scrapHoursByTireTypeGraphInfo));
      setDisplayColumns(scrapHoursByTireTypeDataSetter.setDisplayColumns(scrapHoursByTireTypeGraphInfo));
      setShowSpinner(false);
      if (appliedFilter && appliedFilter.Size && appliedFilter.Size.length) {
        let originalTableGraphStore = scrapHoursByTireTypeGraphInfo;
        let filteredData = scrapHoursByTireTypeDataSetter.filterTable(appliedFilter, originalTableGraphStore);
        setScrapHoursByTireTypeGraphData(filteredData);
        setOriginalScrapHoursByTireTypeTableData(JSON.parse(JSON.stringify(scrapHoursByTireTypeGraphInfo)));
        // static preset printData to allow only graph printing
        setPrintData({
          reportType: "Scrap Tires: ",
          reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
          rowsData: [],
          headCells: [],
          avgValues: [],
          selectedColShow: [],
          filterApplied: false
        });
        setDisplayColOptions(scrapHoursByTireTypeDataSetter.setDisplayColumns(filteredData));
        setDisplayColumns(scrapHoursByTireTypeDataSetter.setDisplayColumns(filteredData));
      }
    });
  };

  useEffect(() => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    ScrapHoursByTireTypeResponseData(excludeUsedTires);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    // when all columns selected

    let showColumnsUpdated = scrapHoursByTireTypeDataSetter.setDisplayColumnData(originalScrapHoursByTireTypeTableData, selected);
    setScrapHoursByTireTypeGraphData(showColumnsUpdated);
  };



  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const handleExcludeUsedTires = (event) => {
    setTableDataSettings({ ...tableDataSettings, excludeUsedTires: event.target.checked });
    setExcludeUsedTires(event.target.checked);
    ScrapHoursByTireTypeResponseData(event.target.checked);
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      outServiceDateToDate: outServiceDateRange.toDate,
      outServiceDateFromDate:  outServiceDateRange.fromDate,
      inServiceDateFromDate: inServiceDateRange.fromDate,
      inServiceDateToDate: inServiceDateRange.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    ScrapHoursByTireTypeResponseData(excludeUsedTires, appliedFilter);
    (((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(appliedFilter).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      Size: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    });
    let appliedFilter = {
      Size: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    }
    ScrapHoursByTireTypeResponseData(excludeUsedTires, appliedFilter);
    setPrintData({
      reportType: "Scrap Tires: ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
      rowsData: [],
      headCells: [],
      avgValues: [],
      selectedColShow: [],
      filterApplied: false
    });
    setFilterOptions({
      categories: [{ name: "Size", value: "Size" }],
      categoryData: {
        Size: [],
        outServiceDateToDate: '',
        outServiceDateFromDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
      }
    })
    setOnlyFilterApplied(false)
  };


  return (
    <div>
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="scrap-cost-by-tire-size">
          <Card className="scrap-cost-by-tire-size-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> Display Tire Type</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllTireType,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                </div>
                <div className="table-function-title">
                  <button
                    onClick={onClickFilter}
                    className="table-function-item"
                    disabled={!scrapHoursByTireTypeGraphData.chartlabels.length}>
                      {!scrapHoursByTireTypeGraphData.chartlabels.length ? 
                      <span>
                        <FilterListIcon fontSize="small" style={{verticalAlign:"middle", marginBottom:5, color:commonUtils.disabledColor}}></FilterListIcon>
                      </span> : 
                      <span>
                          <FilterListIcon fontSize="small" style={{verticalAlign:"middle", marginBottom:5, color:onlyFilterApplied ? 'red': commonUtils.primaryBlue}}></FilterListIcon>
                        </span>
                      }
                  </button>
                  <button
                    onClick={onClickPrint}
                    className="table-function-item"
                    disabled={!scrapHoursByTireTypeGraphData.chartlabels.length}>
                        <span style={{color: !scrapHoursByTireTypeGraphData.chartlabels.length ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                          <PrintIcon disabled={!scrapHoursByTireTypeGraphData.chartlabels.length} fontSize="small" style={{verticalAlign:"middle", marginBottom:5,marginLeft:5}}></PrintIcon>
                        </span>
                  </button>
                  <button
                    className={
                      !scrapHoursByTireTypeGraphData.chartlabels.length === true
                        ? "table-function-item disabled-export-button"
                        : "table-function-item export-button"
                    }
                    disabled={!scrapHoursByTireTypeGraphData.chartlabels.length}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                    ref={ref}
                  >
                    <div className="dropdown-icon-container" ref={ref}>
                      <div className="export-down-arrow">
                        <span style={{color: !scrapHoursByTireTypeGraphData.chartlabels.length ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                          <FileCopyIcon disabled={!scrapHoursByTireTypeGraphData.chartlabels.length} fontSize="small" style={{verticalAlign:"middle", marginBottom:8}}></FileCopyIcon>
                        </span>
                      </div>
                      {isComponentVisible && (
                        <div className="export-dropdown-content">
                          <div
                            onClick={() => onClickExport("pdf")}
                            className="export-option"
                          >
                            {globalizationMapping.MANAGE_PARENT_ACCOUNT.PDF}
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
              </div>
              <Grid container>
                <Grid item xs={4}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <ScrapHoursByTireTypeGraph scrapHoursByTireTypeGraphData={scrapHoursByTireTypeGraphData} />
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
        tableDataSettings={tableDataSettings}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
        tableDataSettings={tableDataSettings}
      />
      <EmailModalComponent
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      />

      <ScrapHoursByTireTypeFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
      />
    </div>
  );
};

export default ScrapHoursByTireType;
