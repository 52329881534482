import React from 'react';
import '../common-modal/CommonPopup.css'
import CommonModal from '../common-modal/CommonPopup'
import Globalization from '../../../globalization';



export default function PdfExportModal(props) {
  const globalization = new Globalization()
  const handleClose = () => {
    props.setModalHandler(false);
  };
  return (
    <div>
      <CommonModal
        title={globalization.REPORTCOMMONWORDS.ConfigureAndExport}
        subtitle={globalization.REPORTCOMMONWORDS.ExportSubTitle}
        onClose={handleClose}
        fieldTitle={globalization.REPORTCOMMONWORDS.ExportChooseFeild}
        openModal={props.modalHandler.handlePdf}
        printOrPdf={"pdf"}
        tableData={props.tableData}
        tableData2={props.tableData2}
        keys={props.keys}
        noGraph={props.noGraph}
        notable={props.notable}
        tableDataSettings={props.tableDataSettings}
      />
    </div>
  );
}
