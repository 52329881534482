import React, { useEffect, useState, useContext } from "react";
import "./TireInstallation.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import TireInstallationDataSetter from "../../../../../services/TireInstallationUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import TireInstallationTable from "../table/TireInstallationTable";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import HeaderApi from "../../../../../../../common-components/header/services/header-apis";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TireInstallationFilter from "../../components/filter/TireInstallationFilter.js";

const TireInstallation = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const commonUtils = new CommonUtils();
  const tireInstallationDataSetter = new TireInstallationDataSetter();

  const [tireInstallationTableData, setTireInstallationTableData] = useState();
  const [originalTireInstallationTableData, setOriginalTireInstallationTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tireInstallationTableData);
  const [printDataNotPDF, setPrintDataNotPDF] = useState(tireInstallationTableData);
  const [installNumber, setInstallNumber] = useState("AllInstalls");
  const [noData, setNoData] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [tableDataSettings, setTableDataSettings] = useState({
    excludeUsedTires: false,
    installNumber: "AllInstalls",
  });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [noOfTreadDepthReadings, setNoOfTreadDepthReadings] = useState(0);
  const headerApiObj = new HeaderApi();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Vehicle ID (Pos)", value: "VehId" },
      { name: "Eval #", value: "Eval" },
      { name: "Compound", value: "Compound" },
      { name: "Rating", value: "RatingName"}
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      VehId: [],
      Eval: [],
      Compound: [],
      RatingName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER


  useEffect(() => {
    TireInstallationResponseData(installNumber, excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const TireInstallationResponseData = (installNumberValue, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let inServiceDateRangeType = "AllTime";
    let entryDateDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (dateRangeFilter.fromDate !== "" && dateRangeFilter.toDate !== "") entryDateDateRangeType = "DateRange";

    let requestObject = {
      reportOption: "",
      reportName: "Tire_Installation",
      dateRangeType: entryDateDateRangeType,
      InServiceDateRangeType: inServiceDateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      installNo: installNumberValue,
      usedTires: excludeUsedTiresValue.toString()
    };

    let filterOptions = {
      categories: [
        {name: globalizationMapping.REPORTTABLE.MANUFACTURER, value: "ManufacturerName"},
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.EVALNO, value: "Eval" },
        { name: globalizationMapping.REPORTTABLE.COMPOUND1, value: "Compound" },
        { name: globalizationMapping.REPORTTABLE.VEHICLEID, value: "VehId" },
        { name: globalizationMapping.REPORTTABLE.RATINGNAME, value: "RatingName"}
      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        VehId: [],
        Eval: [],
        Compound: [],
        RatingName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
      }
    };
    setShowSpinner(true);

    headerApiObj.getLocationById(locationIds[0]).then((accountData) => {
      tiresService.getTiresReport(requestObject).then((data) => {
        let tiresInServiceTableInfo = tireInstallationDataSetter.setData(data);
        let filterOptionsInfo = tireInstallationDataSetter.setFilterOptionsData(filterOptions, tiresInServiceTableInfo.rowsData);
        let updatedHeadCells = tireInstallationDataSetter.updatedHeadCells();

        tiresInServiceTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
        setTireInstallationTableData(tiresInServiceTableInfo);
        setOriginalTireInstallationTableData(tiresInServiceTableInfo);
        setTableBeforeFilter(tiresInServiceTableInfo);
        setFilterOptions(filterOptionsInfo);
        setDisplayColOptions(tiresInServiceTableInfo.headCells);
        setDisplayColumns(updatedHeadCells);
        setShowColumns(tiresInServiceTableInfo.selectedColShow);
        setPrintData({
          reportType: "Tires in Services : ",
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInstallation,
          rowsData: tiresInServiceTableInfo.rowsData,
          headCells: tiresInServiceTableInfo.headCells,
          avgValues: tiresInServiceTableInfo.avgValues,
          selectedColShow: tiresInServiceTableInfo.selectedColShow,
          filterApplied: filterApplied
        });
        let exportData = JSON.parse(JSON.stringify(tiresInServiceTableInfo));

        if (accountData.model) {
          setNoOfTreadDepthReadings(accountData.model.noOfTreadDepthReadings);
          if (accountData.model.noOfTreadDepthReadings === 2 || accountData.model.noOfTreadDepthReadings === 3) {
            exportData.rowsData.forEach((tireRow) => {
              let [OutsideTread, InsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
              tireRow.TreadDepth = OutsideTread;
              tireRow.InsideTread = InsideTread;
              tireRow.OriginalTread = OriginalTread;
            });
            let index = exportData.headCells.findIndex((element) => element.key === "TreadDepth");
            exportData.headCells[index].label = "Outside Tread";

            exportData.headCells.splice(index + 1, 0, {
              key: "InsideTread",
              keyIndex: exportData.headCells.length,
              label: "Inside Tread",
              numeric: false,
              value: "InsideTread"
            });
            exportData.headCells.splice(index + 1, 0, {
              key: "OriginalTread",
              keyIndex: exportData.headCells.length,
              label: "Original Tread",
              numeric: false,
              value: "OriginalTread"
            });
            exportData.selectedColShow.OutsideTread = true;
            exportData.selectedColShow.InsideTread = true;
            exportData.selectedColShow.OriginalTread = true;
          } else {
            exportData.rowsData.forEach((tireRow) => {
              let [OutsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
              tireRow.TreadDepth = OutsideTread;
              tireRow.OriginalTread = OriginalTread;
            });
            let index = exportData.headCells.findIndex((element) => element.key === "TreadDepth");
            exportData.headCells[index].label = "Outside Tread";

            exportData.headCells.splice(index + 1, 0, {
              key: "OriginalTread",
              keyIndex: exportData.headCells.length,
              label: "Original Tread",
              numeric: false,
              value: "OriginalTread"
            });
            exportData.selectedColShow.OutsideTread = true;
            exportData.selectedColShow.OriginalTread = true;
          }
        }
        setPrintDataNotPDF({
          reportType: "Tires in Services : ",
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInstallation,
          rowsData: exportData.rowsData,
          headCells: exportData.headCells,
          avgValues: exportData.avgValues,
          selectedColShow: exportData.selectedColShow,
          filterApplied: filterApplied
        });

        if (filterAppliedValue) {
          setFilterApplied(true);
          setSearchValue("");
          let originalTableDataStore = tiresInServiceTableInfo.rowsData;
          let filteredData = tireInstallationDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
          setTireInstallationTableData({
            ...tiresInServiceTableInfo,
            rowsData: filteredData,
            avgValues: tireInstallationDataSetter.avgValues(filteredData)
          });
          setOriginalTireInstallationTableData({
            ...tiresInServiceTableInfo,
            rowsData: filteredData,
            avgValues: tireInstallationDataSetter.avgValues(filteredData)
          });
          setPrintData({
            ...printData,
            rowsData: filteredData,
            avgValues: tireInstallationDataSetter.avgValues(filteredData),
            filterFieldsApplied: filterFieldsAppliedValue,
            filterApplied: true
          });

          let exportData = JSON.parse(JSON.stringify(filteredData));

          if (noOfTreadDepthReadings === 2 || noOfTreadDepthReadings === 3) {
            exportData.forEach((tireRow) => {
              let [OutsideTread, InsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
              tireRow.TreadDepth = OutsideTread;
              tireRow.InsideTread = InsideTread;
              tireRow.OriginalTread = OriginalTread;
            });
          } else {
            exportData.forEach((tireRow) => {
              let [OutsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
              tireRow.TreadDepth = OutsideTread;
              tireRow.OriginalTread = OriginalTread;
            });
          }
          setPrintDataNotPDF({
            ...printDataNotPDF,
            rowsData: JSON.parse(JSON.stringify(exportData)),
            avgValues: JSON.parse(JSON.stringify(tireInstallationDataSetter.avgValues(filteredData))),
            filterFieldsApplied: JSON.parse(JSON.stringify(filterFieldsAppliedValue)),
            filterApplied: true
          });
          filteredData.length === 0 ? setNoData(true) : setNoData(false);
          (((Object.values(inServiceDateRange).every(x => x === '')) && (Object.values(dateRangeFilter).every(x => x === ''))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
        }
        setShowSpinner(false);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };


  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    //
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const globalizationMapping = new Globalization();

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;
    tableDataStore = originalTireInstallationTableData?.rowsData;
    filteredData = tireInstallationDataSetter.search(tableDataStore, searchText);

    setTireInstallationTableData({
      ...tireInstallationTableData,
      rowsData: filteredData,
      avgValues: tireInstallationDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Tires in Services : ",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInstallation,
      rowsData: filteredData,
      avgValues: tireInstallationDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });

    let exportData = JSON.parse(JSON.stringify(filteredData));

    if (noOfTreadDepthReadings === 2 || noOfTreadDepthReadings === 3) {
      exportData.forEach((tireRow) => {
        let [OutsideTread, InsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
        tireRow.TreadDepth = OutsideTread;
        tireRow.InsideTread = InsideTread;
        tireRow.OriginalTread = OriginalTread;
      });
    } else {
      exportData.forEach((tireRow) => {
        let [OutsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
        tireRow.TreadDepth = OutsideTread;
        tireRow.OriginalTread = OriginalTread;
      });
    }

    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: "Tires in Services : ",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInstallation,
      rowsData: JSON.parse(JSON.stringify(exportData)),
      avgValues: JSON.parse(JSON.stringify(tireInstallationDataSetter.avgValues(filteredData))),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalTireInstallationTableData?.headCells;

    let showColumnsUpdated = tireInstallationDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    let exportData = JSON.parse(JSON.stringify(originalTireInstallationTableData));

    if (noOfTreadDepthReadings === 2 || noOfTreadDepthReadings === 3) {
      exportData.rowsData.forEach((tireRow) => {
        let [OutsideTread, InsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
        tireRow.TreadDepth = OutsideTread;
        tireRow.InsideTread = InsideTread;
        tireRow.OriginalTread = OriginalTread;
      });
      // !affect the pdf and table part when selecting the display table
      // showColumnsUpdated.OutsideTread = true;
      // showColumnsUpdated.InsideTread = true;
      // showColumnsUpdated.OriginalTread = true;
    } else {
      exportData.rowsData.forEach((tireRow) => {
        let [OutsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
        tireRow.TreadDepth = OutsideTread;
        tireRow.OriginalTread = OriginalTread;
      });
      // !affect the pdf and table part when selecting the display table
      // showColumnsUpdated.OutsideTread = true;
      // showColumnsUpdated.OriginalTread = true;
    }
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        rowsData: JSON.parse(JSON.stringify(exportData.rowsData)),
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        rowsData: JSON.parse(JSON.stringify(exportData.rowsData)),
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setFilterApplied(false);
    setCurrentFilter( {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        VehId: [],
        Eval: [],
        Compound: [],
        RatingName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
  });
    setTireInstallationTableData(tableBeforeFilter);
    setOriginalTireInstallationTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    let exportData = JSON.parse(JSON.stringify(tableBeforeFilter));
    if (noOfTreadDepthReadings === 2 || noOfTreadDepthReadings === 3) {
      exportData.rowsData.forEach((tireRow) => {
        let [OutsideTread, InsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
        tireRow.TreadDepth = OutsideTread;
        tireRow.InsideTread = InsideTread;
        tireRow.OriginalTread = OriginalTread;
      });
      let index = exportData.headCells.findIndex((element) => element.key === "TreadDepth");
      exportData.headCells[index].label = "Outside Tread";

      exportData.headCells.splice(index + 1, 0, {
        key: "InsideTread",
        keyIndex: exportData.headCells.length,
        label: "Inside Tread",
        numeric: false,
        value: "InsideTread"
      });
      exportData.headCells.splice(index + 1, 0, {
        key: "OriginalTread",
        keyIndex: exportData.headCells.length,
        label: "Original Tread",
        numeric: false,
        value: "OriginalTread"
      });
      exportData.selectedColShow.OutsideTread = true;
      exportData.selectedColShow.InsideTread = true;
      exportData.selectedColShow.OriginalTread = true;
    } else {
      exportData.rowsData.forEach((tireRow) => {
        let [OutsideTread, OriginalTread] = tireRow.TreadDepth.replace("/", ",").split(",");
        tireRow.TreadDepth = OutsideTread;
        tireRow.OriginalTread = OriginalTread;
      });
      let index = exportData.headCells.findIndex((element) => element.key === "TreadDepth");
      exportData.headCells[index].label = "Outside Tread";

      exportData.headCells.splice(index + 1, 0, {
        key: "OriginalTread",
        keyIndex: exportData.headCells.length,
        label: "Original Tread",
        numeric: false,
        value: "OriginalTread"
      });
      exportData.selectedColShow.OutsideTread = true;
      exportData.selectedColShow.OriginalTread = true;
    }

    setPrintDataNotPDF({
      ...printData,
      ...exportData,
      filterApplied: false,
      filterFieldsApplied: {}
    });

    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    setFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    setSearchValue("");
    let originalTableDataStore = originalTireInstallationTableData?.rowsData;
    let filteredData = tireInstallationDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setTireInstallationTableData({
      ...tireInstallationTableData,
      rowsData: filteredData,
      avgValues: tireInstallationDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tireInstallationDataSetter.avgValues(filteredData)
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      rowsData: JSON.parse(JSON.stringify(filteredData)),
      avgValues: JSON.parse(JSON.stringify(tireInstallationDataSetter.avgValues(filteredData)))
    });

    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
    TireInstallationResponseData(installNumber, excludeUsedTires, true, appliedFilter);
  };


  const handleUpdateInstallNumber = (event) => {
    setTableDataSettings({
      ...tableDataSettings,
      installNumber: event.target.value,
    })
    setInstallNumber(event.target.value);
    TireInstallationResponseData(event.target.value, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setTableDataSettings({
      ...tableDataSettings,
      excludeUsedTires: event.target.checked,
    })
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
    TireInstallationResponseData(installNumber, event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div className="container">
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="tire-installation-container">
          <Card className="tire-installation-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="select-drop-down-container" id="install-number-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.NumberOfInstalls}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      value={installNumber} 
                      name="install number" 
                      variant="outlined" 
                      onChange={handleUpdateInstallNumber}>
                      <option value="AllInstalls">{globalizationMapping.FILTERSECTION.AllInstalls}</option>
                      <option value="FirstInstalls">{globalizationMapping.FILTERSECTION.FirstInstalls}</option>
                      <option value="SubsequentInstalls">{globalizationMapping.FILTERSECTION.SubsequentInstalls}</option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={4} md={4} lg={3} xl={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <TireInstallationTable tableData={tireInstallationTableData} showColumns={showColumns} filterApplied={filterApplied} />
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} tableDataSettings={tableDataSettings}/>
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} tableDataSettings={tableDataSettings}/>
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />

      <TireInstallationFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
      />
    </div>
  );
};

export default TireInstallation;
